html {
  height: 100vh;
  font-size: 1rem;
  width: 100%;
  text-rendering: optimizeSpeed;
}
@font-face {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 1rem;
}

body {
  margin: 0;
  text-align: left;
  min-width: 100%;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  overflow-wrap: break-word;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.wdth100 {
  width: 100%;
}

.wdth97 {
  width: 97%;
}

.edit_hover_class {
  display: none;
}
.edit_hover_class:hover {
  display: block;
}

.mg0 {
  margin: 0px !important;
}

.d_flex {
  display: flex;
  flex-wrap: wrap;
}

.flex_nowrap {
  flex-wrap: nowrap !important;
}

.scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.pos_rel {
  position: relative;
}

.search-summary-content {
  height: auto; /*90vh earlier*/
  overflow-y: auto;
  overflow-x: hidden;
}

.autoHeight {
  height: auto !important;
  max-height: unset !important;
}

.filter-wrapper-style {
  display: flex;
  /*padding-top: 10px;*/
  padding-bottom: 10px;
  padding-left: 10px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

img {
  max-width: 100%;
}

img-transcript {
  max-width: 100%;
  border-radius: 50%;
  width: 30px;
  font-size: 10px;
  height: 30px;
}

.app {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.header {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  border-bottom: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  padding-top: 0px;
  position: fixed;
  z-index: 1050;
}

.header_shareplay {
  height: 50px;
  border-bottom: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--mainColor);
  padding-top: 1px;
}

/*********layout height ***************/
.left-pane-meetings {
  max-height: 100%;
  height: -o-calc(100vh - 410px); /* opera */
  height: -webkit-calc(100vh - 410px); /* google, safari */
  height: -moz-calc(100vh - 410px);
  height: calc(100vh - 410px);
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 2px;
  margin-left: 2px;
}

.left-pane-meeting {
  max-height: 100%;
  height: -o-calc(100vh - 410px); /* opera */
  height: -webkit-calc(100vh - 410px); /* google, safari */
  height: -moz-calc(100vh - 410px);
  height: calc(100vh - 1410px);
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
}

.search-summary-listItem:hover {
  background-color: var(--topBannerColor05);
}

/* where the list is displayed*/
.left-pane-meetings-content {
  max-height: 100vh;
  height: -o-calc(100vh - 160px); /* opera */
  height: -webkit-calc(100vh - 160px); /* google, safari */
  height: -moz-calc(100vh - 160px);
  height: calc(100vh - 160px);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.transcript-content {
  max-height: 100vh;
  height: -o-calc(100vh - 250px); /* opera */
  height: -webkit-calc(100vh - 250px); /* google, safari */
  height: -moz-calc(100vh - 250px);
  height: calc(100vh - 250px);
  margin: 0px;
  padding: 0px 5px 0px 10px;
  background-color: white;
}

.contenthide {
  height: -o-calc(100vh - 163px); /* opera */
  height: -webkit-calc(100vh - 163px); /* google, safari */
  height: -moz-calc(100vh - 163px);
  height: calc(100vh - 163px);
}

.settingspage-content {
  max-height: 100vh;
  height: -o-calc(100vh - 260px); /* opera */
  height: -webkit-calc(100vh - 260px); /* google, safari */
  height: -moz-calc(100vh - 260px);
  height: calc(100vh - 260px);
  margin: 0px;
  padding-left: 5px;
  background-color: white;
  max-width: 100%;
}

.billingpage-content {
  max-height: 100vh;
  height: -o-calc(100vh - 260px); /* opera */
  height: -webkit-calc(100vh - 260px); /* google, safari */
  height: -moz-calc(100vh - 260px);
  height: calc(100vh - 260px);
  margin: 0px;
  padding-left: 5px;
  background-color: white;
  max-width: 100%;
}

.analytics-content {
  height: -o-calc(100vh - 320px); /* opera */
  height: -webkit-calc(100vh - 330px); /* google, safari */
  height: -moz-calc(100vh - 330px);
  height: calc(100vh - 330px);
  max-height: calc(100vh - 330px);
  margin: 0px;
  padding-left: 5px;
  padding-bottom: 100px;
  background-color: white;
  max-width: 100%;
  font-weight: 400;
  font-size: 12px !important;
}

.anaytics-scorecard {
  height: -o-calc(100vh - 320px); /* opera */
  height: -webkit-calc(100vh - 330px); /* google, safari */
  height: -moz-calc(100vh - 330px);
  height: calc(100vh - 330px);
  max-height: calc(100vh - 330px);
  margin: 0px;
  padding-bottom: 100px;
  background-color: white;
  font-weight: 400;
  font-size: 12px !important;
  width: 100%;
  display: flex;
  overflow: hidden auto;
}

/* for header adjusments*/
.leftPanel {
  left: 0px;
  top: 61px;
  width: 429px;
  height: calc(100vh - 61px);
  padding-top: 0px;
  position: fixed;
  display: block;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #ffffff;
  borderleft: "1px solid #CCD5E1";
}
.contentPanel {
  left: 430px;
  width: calc(100vw - 388px);
  height: calc(100vh - 10px);
  position: fixed;
  padding-left: 0px !important;
}
.contentPanel.content-expaned {
  left: 65px;
  position: fixed;
  width: 100vw;
}

/*new starts*/
.contentPanel-meeting {
  left: 430px;
  width: calc(100vw - 388px);
  height: calc(100vh - 61px);
}
.contentPanel-meeting.content-expaned-meeting {
  left: 65px;
  width: 100%; /* earlier it was: 95vw;*/
  margin-left: 4px;
}
/*new ends*/

/* left panel is closed*/
.leftPanel.content-expaned {
  display: none;
}
.infinite-list {
  width: 100%;
  border: 0px solid #ddd;
  height: calc(100vh - 320px);
}
/* content panel left and right*/
.middlepanel {
  width: 100%;
  /*width: calc(100% - 60%);*/
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: var(--transcriptColor);
  overflow-x: hidden;
  padding-left: 15px;
}
.rightMostPanel {
  width: 100%;
  /*width: calc(100% - 60%);*/
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  /*background-color: var(--transcriptColor);*/
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: 1px;
  /*margin-top: 10px;*/
}
.newNotesPanel {
  width: 100%;
  /*width: calc(100% - 60%);*/
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: var(--transcriptColor);
  overflow-x: hidden;
  color: #2d323c;
  font: inherit;
  font-weight: 400;
}

.middlepanel2 {
  /* overflow-x: hidden; */
  /* width: 55%; */
  width: calc(100% - 0%);
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0px 5px 10px 10px;
}
/*middle panel is closed*/
.middle-close .middlepanel {
  display: none;
}

/* .aa .middlepanel {
  display: none;
} */
/*right panel is closed*/
.right-close .middlepanel {
  width: calc(100% - 26px) !important;
}
.content-expaned .right-close .middlepanel {
  width: calc(99% - 26px) !important;
}
.rightpanel {
  margin: 10px 0px 0px 6px;
  width: 65%;
  height: calc(100vh - 160px);
  display: block;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
}
.middle-close .rightpanel {
  height: calc(100vh - 130px);
}
/*close the middle*/
.middle-close .rightpanel {
  width: 100%;
}
/*close the right and left*/
.right-close .rightpanel,
.content-expaned.right-close .rightpanel {
  width: 25px !important;
}

/*close the middle*/

.video-content .rightpanel {
  height: calc(100vh - 130px);
}
.video-content .rightpanel {
  width: 100%;
}
/*resize the player when middle is closed*/
/* .middle-close .player-wrapper {
  width: 100%;
  height: 80vh;
} */

.hide-content .player-wrapper {
  width: 90%;
  height: 70vh;
}

.video-content .player-wrapper {
  width: 100%;
  height: 65vh;
}
/*close the left panel*/
.content-expaned .middlepanel {
  width: 45%;
  max-width: 100%;
}
.content-expaned .rightpanel {
  width: 55%;
}

/*close left and middle*/
.content-expaned .middle-close .rightpanel {
  width: 98.7%;
}

.uploadMeeting {
  background: url("../public/assets/img/upload.svg") no-repeat center;
  max-width: 20px;
  width: 20px;
  height: 20px;
  background-size: cover;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  /*right: 0px;*/
}

/********header ***********/
.logo {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../public/assets/img/logo-icon.svg");
  /*max-width: 36px;*/
  width: 30px;
  height: 30px;
  background-size: cover;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  background-color: var(--mainColor);
  margin-right: 20px;
  cursor: pointer;
}
.subheader {
  background-color: var(--mainColor);
  max-width: 65px;
  margin: 0px;
  padding-top: 10px;
  height: 100%;
}
.logo-big {
  background: url("../public/assets/img/LOGO-white.svg") no-repeat center;
  max-width: 110px;
  width: 110px;
  height: 40px;
  background-size: cover;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin-left: 1.2rem;
}

.logoColor {
  background: url("../public/assets/img/LOGO-01.svg") no-repeat center;
  max-width: 120px;
  width: 50%;
  height: 40px;
  background-size: cover;
  overflow: hidden;
  margin: auto;
  display: flex;
  alignitems: center;
  justifycontent: center;
}
.nav-search-outer {
  flex-shrink: 0;
  width: 28rem;
  /*width: 600px;  for save search*/
  height: 32px;
  border-radius: 10px;
  background-color: #f8f9fc;
  /* overflow: hidden; */
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  border: solid 1px #e1e1e5;
}
@media only screen and (max-width: 900px) {
  .nav-search-outer {
    width: 250px !important;
  }
  .newWidth {
    width: 90% !important;
  }
}

.newWidth {
  width: 100% !important;
}

.nav-search {
  overflow: hidden;
  border: 0px;
  height: 100%;
  padding: 0 0px 0 0px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
}

.nav-search-outer:focus-within {
  border: 1px solid #0052cc;
}
.nav-search-input {
  max-width: calc(100% - 1px);
  font-size: 12px;
  background: rgba(247, 249, 251, 1);
  overflow: hidden;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  border: 0.3px #0052cc !important;
  border-radius: 50px;
  margin-left: 13px;
  height: 36px;
}
.nave-search-action {
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  margin-left: 17px;
  position: absolute;
  /* right: 122px; Modified to remove Saved button temporarily*/
  right: 35%;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 4px 4px;
}

.trans-property {
  width: 20px;
  height: 20px;
  border: solid 1px #e4e5ee;
  background-color: #ffffff;
  margin-left: 17px;
  position: absolute;
  /* right: 122px; Modified to remove Saved button temporarily*/
  right: 130px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 4px 4px;
}

.nave-search-select__indicator-separator {
  display: none;
}

.nave-search-select__placeholder {
  top: 35% !important;
  padding: 0px !important;
  color: #2d323c !important;
  font-weight: 400 !important;
}

.nave-search-select__single-value {
  top: 35% !important;
}

.css-tlfecz-indicatorContainer {
  /*top: 35% !important;*/
  /*margin-bottom: 10px !important;*/
  color: white !important;
}

.css-1gtu0rj-indicatorContainer {
  /*  position: absolute;*/
  /*  top: 35%;*/
  /*  right: 25%;*/
  /*  padding: 0px !important;*/
  color: white !important;
}

.css-19bqh2r {
  height: 14px;
  -webkit-text-stroke-color: #2d323c;
  stroke-width: 0.5 !important;
  stroke: #2d323c !important;
}

.css-6q0nyr-Svg {
  height: 14px;
  stroke-width: 0.5 !important;
  stroke: #2d323c !important;
}

.nav-search-select {
  position: absolute;
  right: 8px;
  top: 0px;
}
.nave-search-select {
  /*background: #ffffff;*/
  width: 100px;
  border: 0px;
  margin-top: 1px;
  height: 100% !important;
  margin-bottom: 8px;
  white-space: nowrap;
  display: inline-block;
  top: 0;
  left: 0.2rem;
  border-radius: 0px;
}
.nave-search-select__control {
  background-color: rgba(88, 89, 255, 0.1) !important;
  color: #2d323c !important;
  font-weight: 700;
  box-sizing: border-box;
  width: 100px;
  height: 28px !important;
  border-radius: 10px !important;
  min-height: 28px !important;
  margin-bottom: 0px;
  padding-top: 2px !important;
}

.nave-search-select__menu {
  min-width: 300px;
}

.metrics-dropdown-wrapper {
  width: inherit;
  padding-right: 8px;
  padding-left: 12px;
  align-self: center;
  justify-content: space-between;
  /* bottom: -2px; */
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  font-size: 11px;
}
.metrics-dropdown-container {
  align-content: center;
  width: 85px;
  /* height: 30px; */
  border-radius: 20px;
  /*background-color: var(--mainColor);*/
  background-color: #5c2c5d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 100px;
  margin-right: 5px;
  margin-bottom: -4px;
}
.metrics-dropdown-text {
  color: #fff !important;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 0;
}
.metrics-dropdown-container:hover {
  background-color: #8c6b8d !important;
  /* border: 1px solid white; */
  cursor: pointer;
  /* margin-bottom: -2px;
   height: 30px; */
}
.metrics_dropdown_img {
  background: url("../public/assets/img/down-arrow.svg");
  width: 10px !important;
  height: 10px !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: 10px !important;
  float: right;
  justify-content: center;
  padding: 8px;
}

.dropDownFilterPanel__menu {
  min-width: 160px;
  border: 1px solid var(--mainColor);
  overflow: hidden;
}

.dropDownFilterPanel__control {
  min-height: 25px !important;
  /*background-color: transparent !important;*/
  /*border-color: transparent !important;*/
  color: #2d323c !important;
  font-weight: 400 !important;
}
.dropDownFilterPanel__placeholder {
  color: #2d323c !important;
}
.dropDownFilterPanel__single-value {
  color: #2d323c !important;
}
.dropDownFilterPanel__indicator {
  color: hsl(0, 0%, 50%) !important;
}
.css-1a4e434-control {
  background-color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
  color: white !important;
  font-weight: 600 !important;
  outline: #d0c6dd !important;
}

.search-icon {
  color: #555;
  left: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 20px;
  display: inline-block;
  height: 20px;
}
.search-icon img {
  vertical-align: inherit;
  max-width: 100%;
}
.more {
  width: 22px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/more.svg");
  display: inline-block;
  background-repeat: no-repeat;
  margin: 4px;
  background-size: 20px 20px;
  cursor: pointer;
}

.activeMoreFilter {
  border: 2px solid var(--mainColor);
  padding: 2px;
  border-radius: 4px;
  width: 25px;
}

.newFilter {
  width: 20px;
  height: 20px;
  object-fit: contain;
  /*background: url("../public/assets/img/newFilter.svg");*/
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  color: #ffffff;
}

.property {
  width: 18px;
  height: 16px;
  object-fit: contain;
  background: url("../public/assets/img/property.svg");
  background-color: rgba(247, 249, 251, 1);
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  padding-top: 20px;
}

.activeProperty {
  width: 16px;
  height: 16px;
  /*object-fit: contain;*/
  background-color: #fff;
  background-repeat: no-repeat;
  cursor: pointer;
  background: url("../public/assets/img/property_blue.svg");
}

.activePropertyTrans {
  width: 16px;
  height: 16px;
  object-fit: contain;
  background-color: #fff;
  background-repeat: no-repeat !important;
  background: url("../public/assets/img/property_blue.svg") no-repeat !important;
  padding-top: 5px !important;
  padding-left: 5px !important;
}
.clearfilter-icon-topnav {
  background: url("../public/assets/img/clearfilter_grey_icon.svg");
  background-repeat: no-repeat !important;
  position: absolute;
  width: 16px;
  height: 16px;
  right: 24%;
  top: 30%;
}

.filter-icon {
  color: #555;
  left: 80%;
  top: 53%;
  transform: translateY(-50%);
  position: absolute;
  width: 30px;
  display: inline-block;
  height: 30px;
  border-radius: 4px 4px 4px 4px;
  padding-top: 5px;
  padding-left: 5px;
  align-items: center;
  justify-content: center;
  border: solid 1px #e4e5ee;
  color: #e4e5ee;
  right: 4px;
}

.filter-icon img {
  vertical-align: inherit;
  justify-items: center;
  justify-content: center;
  max-width: 100%;
}

.header-menu {
  width: 35rem;
  position: relative;
  display: inline-block;
  height: 100%;
  margin-left: 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.app-body {
  /*background-color: var(--topBannerColor1);*/
  overflow: hidden;
  margin: 0px !important;
  height: calc(100vh - 60px);
  display: flex;
}

.app-body .main {
  flex: 1 1;
  overflow-x: hidden;
}
.header-menu-navbar-nav {
  top: 0;
  position: relative;
  height: 100%;
}
.navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 100%;
  align-items: center;
}

.header-menu-navbar-nav .navbar-nav {
  padding-right: 0px;
}

.header-menu-navbar-nav .navbar-nav .nav-item {
  padding: 0px !important;
  max-width: 95px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.navbar-nav > .nav-item.active > .nav-link.active {
  position: relative;
  overflow: hidden;
  font-weight: 700;
  /*background-color: #FFBE00;*/
}

.navbar-nav > .nav-item.active > .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  /*background-color: #FFBE00;*/
  left: 0;
  right: 0;
}

.navbar-nav .nav-link {
  line-height: 20px;
  padding-right: 0px;
  margin-top: 0;
  height: 90%;
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: 500;
}
.sidebar .nav-item:hover,
.sidebar .nav-item .nav-link:hover {
  width: auto;
  height: auto;
  background-color: var(--topBannerColor05);
}

.navbar-nav .open > .nav-link,
.navbar-nav .open > .nav-link:hover,
.navbar-nav .open > .nav-link:focus,
.navbar-nav .active > .nav-link,
.navbar-nav .active > .nav-link:hover,
.navbar-nav .active > .nav-link:focus,
.navbar-nav .nav-link.open,
.navbar-nav .nav-link.open:hover,
.navbar-nav .nav-link.open:focus,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link.active:focus {
  color: white;
  font-weight: 700;
}
/*.navbar-nav .nav-link.active {*/
/*  color: #0052cc;*/
/*}*/

.navbar-nav .nav-link .active {
  color: white;
}
/*.navbar-nav .nav-link:hover {*/
/*  color: #0052cc;*/
/*  font-weight: 700;*/
/*  box-shadow: inset 100px 100px 100px 100px rgba(0, 0, 255, 0.1);*/
/*  filter: invert(100%) sepia(97%) saturate(13%) hue-rotate(244deg) brightness(203%) contrast(104%);*/
/*}*/

.newside .nav-text {
  padding-top: 5px;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 0.8rem;
  color: white;
  background: transparent;
}

.newside .nav-text:active {
  font-weight: bold !important;
}

.newside.nav-link {
  height: 100% !important;
}

.nav-text {
  padding-top: 5px;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background: transparent;
}

.navbar-nav .nav-link .nav-line {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 100%; /* Change this to whatever width you want. */
  padding-left: 0px;
  padding-right: 0px;
}
.navbar-nav .nav-link .nav-text:hover {
  color: white !important;
}

.navbar-nav .nav-link .svg:hover .nav-text {
  color: white !important;
}
.navbar-nav .nav-link .nav-text:hover,
.navbar-nav .nav-link .nav-text:active {
  color: white !important;
}

.navbar-nav .nav-link.active .nav-line {
  border-bottom: 3px solid white !important;
  font-weight: 500;
  content: "";
  padding-bottom: 8px;
  width: 60px;
  height: 100%;
  flex: 1 1 auto; /* the first digit is 'flex-grow: 1', helps element to occupy all free space */
}
.navbar-nav .nav-link.active .nav-line .nav-text {
  color: white !important;
}
.navbar-nav .nav-link.active .nav-line svg {
  color: white !important;
  /*filter: invert(76%) sepia(49%) saturate(374%) hue-rotate(3deg) brightness(306%) contrast(110%) !important;*/
}

.navbar-nav .nav-link:hover .nav-line svg {
  color: white !important;
  /*filter: invert(76%) sepia(49%) saturate(374%) hue-rotate(3deg) brightness(306%) contrast(110%) !important;*/
}

.navbar-nav .nav-link.active {
  /*border-bottom: 2px solid white !important;*/
  font-weight: 500;
  content: "";
  padding: 3px;
  flex: 1 1 auto; /* the first digint is 'flex-grow: 1', helps elemet to occupy all free space */
}
.header-icon .nav-link {
  padding: 0px;
}

.header_icons {
  width: auto;
  float: right;
  margin: 0 22px 0px auto;
  display: flex;
  gap: 10px;
}

.header-icon {
  width: 20px !important;
  height: 20px !important;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 1.2rem;
}
.header-setting-icon .nav-link {
  position: relative;
}
.header-setting-icon .nav-link img {
  position: absolute;
  transition: 0.45s;
}
.header-setting-icon .nav-link .setting-blue-img {
  opacity: 0;
}
.header-setting-icon .nav-link.active .setting-blue-img {
  opacity: 1;
}
.header-setting-icon .nav-link.active .setting-gray-img {
  opacity: 0;
}
.notes-search-outer {
  width: 360px;
  height: 40px;
  border-radius: 4px;
  background-color: #f8f9fc;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 27px;
}

.notes-search-action {
  width: 40px;
  height: 40px;
  border: solid 1px #e4e5ee;
  background-color: #ffffff;
  margin-left: 17px;
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #2d323c;
  background-color: #ffffff;
  display: inline-block;
  position: relative;
  border: 0px;
}
/*---------------------left ----------------*/
.meetings-header {
  height: 50px !important;
  padding-top: 0px;
  margin-bottom: 15px;
  /*background-color: var(--mainColor);*/
  color: white;
  font-weight: 700;
}
.meetings {
  left: 18px;
  top: 16px;
  width: 28%;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2d323c;
  background-color: #ffffff;
  position: absolute;
  white-space: nowrap;
  word-break: keep-all;
  padding-top: 0px;
}
.meeting-tab-body {
  margin-top: 0px;
}
.tab-border {
  border: 0px;
  cursor: pointer;
}

.n-disp {
  margin-left: 4px;
}

.meeting-expand {
  left: 298px;
  position: absolute;
  top: 24px;
  display: inline-block;
}
.meeting-fullview {
  left: 338px;
  position: absolute;
  top: 24px;
  display: inline-block;
}
.meeting-fullview img,
.meeting-expand img {
  vertical-align: initial;
}
.leftPanelTab {
  padding-left: 62px;
  border-right: 0.5px solid #ccd5e1;
}
.nav {
  marginleft: -5px;
}

.leftPanelTab .nav.nav-tabs {
  border: 0px;
}
.leftPanelTab .nav {
  margin-left: -15px;
}
.leftPanelTab .nav,
.filterpanelHeader {
  height: 36px;
  padding-bottom: 3px;
}
.leftPanelTab .nav-link,
.filterpanelHeader {
  display: block;
  text-align: center;
  padding-top: -5px;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.filterMetrics {
  height: 400px !important;
  padding: 5px 15px 10px 0px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.list-group-item {
  border: 0px;
  padding: 2px 0px;
}

.list-group-item.active {
  border-radius: 4px;
  border: solid 0.2px var(--mainColor);
}

.infinite-row .list-group-item {
  border: 0px;
  padding: 0px;
}
.infinite-row .list-group-item.active {
  z-index: 2;
  color: #fff;
  width: 100%;
  /*border: solid 1px var(--mainColor);*/
  background-color: var(--topBannerColor09) !important;
}
.meeting-card .v64_7219 .v64_7220 .meeting-first-row {
  font-weight: 800;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  margin-top: 2px;
  /*border: solid 1px var(--mainColor);*/
  background-color: var(--topBannerColor09) !important;
}
.list-group-item.active .meetingmenu {
  opacity: 1;
}

.leftPanelTab .nav-item,
.filterpanelHeader {
  line-height: 1.5;
  letter-spacing: normal;
  color: #2d323c;
  /*background: transparent;*/
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  color: #202124;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.leftPanelTab .nav-link:hover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: var(--topBannerColor09);
}

.leftPanelTab .nav-link {
  border: 0px;
}
.leftPanelTab .nav-link.active .tab-border {
  font-weight: 600;
}

.leftPanelTab .nav-link.active {
  border-bottom: 4px solid var(--mainColor) !important;
  background-color: var(--topBannerColor1);
}

.leftPanelTab .nav > li {
  /* width = (100 / number of tabs). This example assumes 3 tabs. */
  width: 33.3%;
}
.leftPanelTab .tab-content {
  border: 0px;
  margin-right: 1px;
  margin-top: 6px;
}
.leftPanelTab .tab-pane {
  padding: 0px;
  margin: 0px;
}

.infinite-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 100px;
  background-color: #fff;
  border: 0px;
}

/************* meeting card-----*/
.meeting-card {
  width: 355px;
  height: 90px;
  opacity: 1;
  overflow: hidden;
  border-bottom: 0.5px solid #e4e5ee;
  transition: all 0.5s ease;
}
.meeting-card.active .meetingmenu {
  opacity: 1;
}

.list-group-item:hover {
  background-color: var(--topBannerColor04) !important;
}
.meeting-first-row {
  position: absolute;
  top: 6px;
  display: inline-block;
  padding-left: 8px;
}
.meeting-title {
  color: rgba(44, 49, 59, 1);
  position: relative;
  top: 0px;
  left: 0px;
  width: 12rem;
  height: 21px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  display: inline-block;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.meeting-title_summary {
  color: #2d323c;
  position: relative;
  top: 0px;
  left: 10px;
  height: 20px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.meeting-title-highlight_summary {
  position: relative;
  top: 0px;
  left: 0px;
  height: 20px;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.card-body {
  padding: 0 0 0 3px;
  margin: 0;
}
.meeting-status {
  font-size: 11px;
  line-height: 1.1;
  letter-spacing: -0.06em;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  top: -7px;
  padding: 2px 5px;
  width: 8rem;
}

.meeting-top-row-action {
  background-color: #a9acbd;
  background: url("../public/assets/img/Group.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 12px;
  height: 20px;
}

.deal-top-row-action {
  background-color: #a9acbd;
  background: url("../public/assets/img/Group.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 12px;
  height: 20px;
}

.playlist-top-row-action {
  background-color: #a9acbd;
  background: url("../public/assets/img/Group.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 12px;
  height: 20px;
}

.meeting-first-row-action,
.snippet-icon {
  background-color: #a9acbd;
  background: url("../public/assets/img/Group.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 12px;
  height: 20px;
  /* background-size: 20px; */
}
.meetingmenu.dropdown.nav-item a.nav-link {
  padding: 0rem 0rem;
}
.meeting-second-row {
  width: 303px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 7.8%;
  left: 0px;
  overflow: hidden;
  display: inline-block;
}
.meeting-second-row-time-pos {
  position: absolute;
  top: 37%;
  width: 177px;
}
.meeting-second-row-time {
  font-weight: normal;
  font-size: 12px !important;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  padding-left: 8px;
  width: 13rem;
}

.meeting-second-row-ratio-pos {
  position: absolute;
  top: 40%;
  left: 57%;
;
  width: 114px;
  text-align: right;
  justify-items: right;
}
.meeting-second-row-ratio {
  color: #202124;
  font-weight: normal;
  font-size: 12px !important;
  opacity: 1;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meeting-third-row-customer {
  width: 170px;
  height: 21px;
  position: absolute;
  top: 61%;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  color: #0052cc;
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
  padding-left: 8px;
}

.meeting-third-row-ellipse-4 {
  top: 72.1%;
  left: 47%;
  position: absolute;
}
.meeting-third-row-count {
  width: 17px;
  height: 21px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  top: 61.3%;
  left: 52%;
  display: inline-block;
  position: absolute;
}
.meeting-third-row-group-1 {
  top: 59.5%;
  left: 56%;
  object-fit: contain;
  background: url("../public/assets/img/groups.svg");
  position: absolute;
}
.trans-search-followup {
  width: 33%;
  height: 40px;
  background-color: #fcf8f8;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-left: 12px;
}

.icon {
  position: absolute;
}

.search-icon1 {
  left: 8px;
  width: 20px;
  height: 20px;
}

.clear-icon1 {
  position: absolute;
  right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.search-input1 {
  flex-grow: 1;
  border: none;
  padding: 8px 20px;
  outline: none;
}
.search-input-followup {
  flex-grow: 1;
  border: none;
  padding: 0px 5px 0px 30px;
  outline: none;
}


.meeting-tab-comments {
  position: relative;
  display: inline;
}
.meeting-tab-comments .mtc-icon {
  padding-left: 8px;
}
.meeting-tab-comments .mtc-icon:hover ~ .app-tooltip {
  opacity: 1;
  top: -36px;
  cursor: pointer;
}
.groups {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/groups.svg");
  display: inline-block;
}
.down-arrow-1 {
  background: url("../public/assets/img/down-arrow.svg");
  width: 10px;
  height: 10px;
  object-fit: contain;
}
.right-arrow {
  background: url("../public/assets/img/expand-left.svg");
  width: 16px;
  height: 40px;
  object-fit: contain;
  display: block;
}
.left-arrow {
  background: url("../public/assets/img/expand-right.svg");
  width: 16px;
  height: 40px;
  object-fit: contain;
  display: block;
}
.plus {
  background: url("../public/assets/img/plus.svg");
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-top: 5px;
  margin-left: 12px;
}
.img-24 {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.actionImg {
  cursor: pointer;
}
.ellipse {
  background-color: #70738b;
  width: 4px;
  height: 4px;
  border-radius: 0.25rem;
  display: inline-block;
}
.ellipse-col {
  max-width: 4px;
  padding: 0px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
.img-valign-bottom {
  vertical-align: bottom;
}
.meeting-third-row-ellipse-5 {
  top: 71.6%;
  left: 67%;
  position: absolute;
}
.meeting-third-row-status {
  width: 60px;
  height: 21px;
  position: absolute;
  top: 62%;
  left: 73%;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  display: inline-block;
}

.meeting-third-row-recording {
  width: 100px;
  height: 21px;
  position: absolute;
  top: 62%;
  left: 60%;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  display: inline-block;
}

.meeting-third-row-recording-switch {
  width: 50px;
  height: 21px;
  position: absolute;
  top: 58.4%;
  left: 82%;
  color: #202124;
  display: inline-block;
  margin-left: 5px;
}

.meeting-third-row-vector {
  width: 12px;
  height: 12px;
  object-fit: contain;
  top: 66.2%;
  left: 88%;
  background-color: #a9acbd;
  background: url("../public/assets/img/vector.svg");
  display: inline-block;
  position: absolute;
}

.summary-filter-info {
  display: inline-block;
}

.meeting-summary-info {
  width: 12px;
  height: 12px;
  object-fit: contain;
  background-color: rgba(0, 82, 204);
  background: url("../public/assets/img/vector.svg");
  position: absolute;
  top: 95%;
  margin-left: 10px;
  cursor: pointer;
}
.main-graph {
  margin: 10px 30px 0px 25px;
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.1);
  border: 0.5px solid #e1e1e5;
  border-radius: 16px;
  padding: 0px 10px 0px 0px;
}

.filter-list-wrapper {
  display: table;
  clear: both;
}

.filter-item-content {
  float: left;
  min-width: 50%;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.modal-content-search {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
}

/*middle*/
.dashboard-content {
  margin: 0px;
  padding: 0px;
  margin-left: 10px;
  border: 0px;
}

.dashboard-content div.card {
  border: 0px;
}

.contentPanel .tab-content,
.contentPanel .tab-content div.tab-pane {
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.center-toggle-label {
  vertical-align: middle;
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 0;
  margin-bottom: 0px;
}
.video-panel {
  width: 100%;
  /*height: 100%;*/
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid #e4e4e4;
}

.video-panel > .flex_nowrap {
  flex: none;
  padding-bottom: 0px;
  justify-content: center;
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.control_wrap {
  width: 100%;
  /*flex: 1 1 auto;*/
  display: flex;
  flex-direction: column;
}
.followupContainer .control_wrap {
  flex: none;
}

.video-react {
  background-color: rgba(88, 89, 255, 1) !important;
  border-radius: 8px !important;
}

.control_wrap .font-14,
.control_wrap .font-24 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player-wrapper {
  /*  width: 100%;*/
  /*  height: 55vh;*/
  /*  margin: auto;*/
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* for 16:9 aspect ratio */
}

.player-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player-wrapper-shareplay {
  /*width: 100%;*/
  /*height: 65vh;*/
  /*margin: auto;*/
  width: 100%;
  position: relative;
  justify-content: center;
  padding-top: 56.25%; /* for 16:9 aspect ratio */
}

.player-wrapper-followup {
  /*width: 100%;*/
  /*height: 40vh;*/
  /*margin: auto;*/
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* for 16:9 aspect ratio */
}

.video-panel-bottom {
  width: 100%;
  /* height: calc(100vh - 728px); */
  overflow-y: auto;
  margin: 0 auto;
}
.middle-close .video-panel-bottom {
  height: auto;
  overflow-y: auto;
}
.right-close .video-panel-bottom,
.right-close .overview-container {
  display: none;
}
.right-close .videoplayer {
  display: none;
}
.middle-close .overview-container {
  display: block;
  margin: 10px auto 0 auto;
  width: 100%;
  background-color: white;
}
.player-wrapper-overlay {
  width: 100%;
  height: 800px;
  background-color: #fff;
  display: block;
  margin-top: -480px;
  z-index: 2000;
  position: relative;
  text-align: center;
}
.transcriptName {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  margin-left: 13px;
  padding-left: 1.5rem;
  flex-wrap: nowrap !important;
  width: auto !important;
}

.transcriptsTime {
  width: 4rem;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  padding-top: 2px;
}
.transcriptsComment {
  width: 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  padding-top: 2px;
}

.transcriptText {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2d323c;
  padding-left: 13px;
}

.highlightedText em {
  background-color: #fdff32;
  padding: 1px;
}

.transcriptText .dots {
  opacity: 0;
}
.transcriptText:hover .dots {
  opacity: 1;
}
.transcriptCategories {
  border-radius: 70px;
  height: 20px;
  padding: 3px 6px;
  font-size: 11px;
  /*font-weight: 500;*/
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  background-color: rgba(0, 84, 204, 0.1);
  color: #0052cc;
  /* margin-top: 2px; */
}
.transcript-row {
  padding-top: 8px;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap !important;
  width: auto !important;
}

.transcript-rows {
  padding-top: 8px;
  padding-left: 6px;
  justify-content: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.transcript-row-text {
  padding-left: 6px;
  margin-right: 25px;
  justify-content: space-evenly;
}

.same-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 5px;
}
.trans-search-input {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  height: 100%;
  letter-spacing: normal;
  background-color: #fff;
  width: 100%;
  display: inline-block;
  border: 1px solid hsl(0, 0%, 80%) !important;
  padding-left: 10px;
  padding-right: 65px;
}
.trans-property {
  /* border-radius: 4px 4px 4px 4px; */
  right: 0px;
  margin-left: 0px;
}

.clear-property {
  border-radius: 1px 8px 8px 1px;
  right: 0px;
  margin-left: 0px;
}

input.trans-search-input::placeholder {
  color: #a9acbd;
}

.followup {
  object-fit: contain;
  background: url("../public/assets/img/followup_white.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 6px 0px 0px;
  align-items: center;
}
.followup:hover {
  background-color: #0052cc;
  fill: #0052cc;
}

.newshare {
  object-fit: contain;
  background: url("../public/assets/img/share_white.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 0px 0px 0px;
  align-items: center;
}
.newshare:hover {
  color: #0052cc;
  fill: #0052cc;
}

.newcomments {
  object-fit: contain;
  background: url("../public/assets/img/comments_white.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 3px 0px 0px;
  align-items: center;
}
.newcomments:hover {
  color: #0052cc;
  fill: #0052cc;
}

.newsnippets {
  object-fit: contain;
  background: url("../public/assets/img/snippets_white.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 3px 0px 0px;
  align-items: center;
}
.newsnippets:hover {
  color: #0052cc;
  fill: #0052cc;
}

.newnotes {
  object-fit: contain;
  background: url("../public/assets/img/notes.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 3px 0px 0px;
  align-items: center;
}
.newnotes:hover {
  color: #0052cc;
  fill: #0052cc;
}
.emoji {
  object-fit: contain;
  background: url("../public/assets/img/emoji.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  margin: -2px 3px 0px 0px;
  align-items: center;
  border: none !important;
}
.emoji:hover {
  color: #0052cc;
  fill: #0052cc;
}

.newtopics {
  object-fit: contain;
  background: url("../public/assets/img/newtopics.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin: -2px 3px 0px 0px;
  align-items: center;
}
.newtopics:hover {
  color: #0052cc;
  fill: #0052cc;
}
.newtranscripts {
  object-fit: contain;
  background: url("../public/assets/img/newtranscripts.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 5px 0px 0px;
  align-items: center;
}
.newtranscripts:hover {
  color: #0052cc;
  fill: #0052cc;
}

.comments_header {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background: url("../public/assets/img/comments_grey.svg");
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
  horiz-align: middle;
  background-repeat: no-repeat;
}
.comments_header:hover {
  color: #0052cc;
  fill: #0052cc;
}

.comments_header_active {
  background: url("../public/assets/img/comments_blue.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  object-fit: contain;
  display: inline-block;
  margin: 5px;
  horiz-align: middle;
  vertical-align: middle;
}

.scorecard_header {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background: url("../public/assets/img/scorecard_grey.svg");
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.scorecard_header:hover {
  color: #0052cc;
  fill: #0052cc;
}

.switch_btn {
  display: inline-block;
  white-space: nowrap;
  padding-left: 8px;
}

.toggle_switch {
  display: inline-flex;
  align-items: center;
  width: 240px;
}

.timebar {
  padding-left: 30px;
  padding-right: 25px;
  width: 100%;
}
.timeBar-container {
  padding-bottom: 10px;
  margin-left: 0px;
}
.roundedRect {
  border-radius: 50px;
}

.overview-container {
  display: block;
  /* padding-left: 27px !important; */
  margin: 10px auto 0 auto;
  white-space: nowrap;
}

.more-Participants {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #0052cc;
  padding-top: 22px;
}
.blue-down-arrow {
  width: 8px;
  height: 8px;
  object-fit: contain;
  margin-bottom: 3px;
}
.bar-row {
  background-color: rgba(88, 89, 255, .1) !important;
  height: 16px;
  padding: 0px;
  margin: 0px;
  display: inline-flex;
  width: 100%;
  position: relative;
  border-radius: 4px;
}
.video-expand {
  max-width: 16px;
}

.videoplayer {
  padding-right: 0px;
}

.middle-close .video-right-expand {
  display: none;
}
.right-close .video-left-expand {
  display: none;
}
.video-left-expand {
  padding: 0px;
  margin: 0px;
  background-repeat: no-repeat;
  margin-top: 10px;
  padding-left: 15px;
}
.video-right-expand {
  padding: 0px;
  margin: 0px;
  background-repeat: no-repeat;
  margin-top: 2px;
  padding-left: 15px;
}
.tran-left-expand {
  background-repeat: no-repeat;
}

.line-21 {
  width: 98%;
  height: 0.5px;
  margin-top: 15px;
  /*margin-bottom: 15px;*/
  border-radius: 5px;
  /* border: 2px solid red; */
  margin-left: 16px;
  margin-right: 8px;
  /* color: burlywood; */
  /* border-block-color: #a9acbd; */
  /* border-style: dashed; */
  /* border-style: dashed; */
  border: 1px dashed #a9acbd;
}

.tran-line {
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  border-style: solid;
}
.marker-line {
  display: inline-block;
  position: absolute;
  border-style: solid;
  height: 1rem;
  width: 1rem;
  background-color: #bbb;
  border-radius: 50%;
  border-color: var(--mainColor);
  display: inline-block;
}
.marker-line.marked {
  background-color:var(--mainColor) !important;
  color: #2d323c;
  font-weight: 500;
}
.marker-line.visible {
  background-color: var(--topBannerColor1) !important;
}
.it-card.marked {
  background-color: var(--topBannerColor1) !important;
  color: #2d323c;
  font-weight: 500;
}
/*remote*/
.video-remotepanel {
  max-width: 900px;
  height: 60px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: rgba(88, 89, 255, 0.1) !important;
  border-radius: 16px;
}
.remote-dashboard.normal {
  width: 24px;
  height: 18px;
  object-fit: contain;
  background: url("../public/assets/img/rate-1.svg");
  display: inline-block;
  background-repeat: no-repeat;
}

.dashboard {
  width: 24px;
  height: 18px;
  background: url("../public/assets/img/dashboard.svg");
  display: inline-block;
  background-repeat: no-repeat;
  object-fit: contain;
}
.remote-dashboard.half {
  width: 24px;
  height: 18px;
  background: url("../public/assets/img/rate-.5.svg");
  background-repeat: no-repeat;
  object-fit: contain;
  display: inline-block;
}
.remote-dashboard.slow {
  width: 24px;
  height: 18px;
  background: url("../public/assets/img/rate-slow.svg");
  background-repeat: no-repeat;
  object-fit: contain;
  display: inline-block;
}
.remote-dashboard.two {
  width: 24px;
  height: 18px;
  background: url("../public/assets/img/rate-2.svg");
  background-repeat: no-repeat;
  object-fit: contain;
  display: inline-block;
}
.remote-dashboard.full {
  width: 24px;
  height: 18px;
  background: url("../public/assets/img/rate-full.svg");
  background-repeat: no-repeat;
  object-fit: contain;
  display: inline-block;
}
.rewind {
  width: 16px;
  height: 16px;
  background: url("../public/assets/img/rewind.svg");
  display: inline-block;
  object-fit: contain;
  background-repeat: no-repeat;
}

.fastforward {
  width: 16px;
  height: 16px;
  background: url("../public/assets/img/fast-forward.svg");
  display: inline-block;
  object-fit: contain;
  background-repeat: no-repeat;
}

.play {
  width: 36px;
  height: 36px;
  background: url("../public/assets/img/play-button.svg");
  display: inline-block;
  object-fit: contain;
  background-repeat: no-repeat;
  /* margin-left: 15px; */
}

.mic {
  width: 36px;
  height: 36px;
  background: url("../public/assets/img/mic.svg");
  display: inline-block;
  object-fit: contain;
  background-repeat: no-repeat;
  /* margin-left: 15px; */
}

.recbutton {
  width: 36px;
  height: 36px;
  background: url("../public/assets/img/record.svg");
  display: inline-block;
  object-fit: contain;
  background-repeat: no-repeat;
  /* margin-left: 15px; */
}
.recbutton.stoprecord {
  width: 36px;
  height: 36px;
  object-fit: contain;
  display: inline-block;
  background: url("../public/assets/img/stoprecord.svg");
  background-repeat: no-repeat;
}

.play.pause,
.mic.pause {
  width: 36px;
  height: 36px;
  object-fit: contain;
  display: inline-block;
  background: url("../public/assets/img/pause.svg");
  background-repeat: no-repeat;
}

.mic.stoprecord {
  width: 36px;
  height: 36px;
  object-fit: contain;
  display: inline-block;
  background: url("../public/assets/img/stoprecord.svg");
  background-repeat: no-repeat;
}

.speaker {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/speaker.svg");
  display: inline-block;
  background-repeat: no-repeat;
}
.speaker.mute {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/speaker-mute.svg");
  display: inline-block;
  background-repeat: no-repeat;
}

.speaker.active {
  background-color: #60606040;
  padding: 13px;
  background-position: center;
}

.vol-control {
  display: inline-block;
  width: 20px;
  height: 100px;
  padding: 0;
  position: absolute;
  top: -165px;
  left: 0px;
}

.vol-control-input {
  width: 100px;
  height: 20px;
  margin: 0;
  transform-origin: 75px 75px;
  transform: rotate(-90deg);
}

.speaker-col p {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: smaller;
  top: 165px;
  left: 6px;
  position: relative;
  text-align: center;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.vol-text {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: smaller;
  top: 15px;
  left: -21px;
  color: #70738B;
  font-weight: 500;
  position: relative;
  text-align: center;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.fullview {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/fullview.svg");
  display: inline-block;
  background-repeat: no-repeat;
}
.reduce_grey {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/reduce_grey.svg");
  display: inline-block;
  background-repeat: no-repeat;
}
.fullview_grey {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/fullview_grey.svg");
  display: inline-block;
  background-repeat: no-repeat;
}
.userprofile-modal {
  padding: 15px;
  overflow: hidden;
  height: 800px;
}
.sharelink-modal {
  padding: 15px;
  overflow: hidden;
  height: 400px;
}

.rate {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #202124;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  padding-left: 2px;
}

.rewind-rate {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.ff-rate {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.video-remotepanel {
  position: relative;
  background-color: rgba(88, 89, 255, 0.1) !important;
}
.dashboard-col {
  top: 0px;
  left: 0%;
  position: relative;
  display: inline-block;
}
.rate-col {
  top: 0px;
  left: 1.8%;
  width: 30px;
  position: relative;
  display: inline-block;
  font-size: 11px;
}
.play_controls {
  display: inline-flex;
  position: absolute;
  width: auto;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}
.topic_button {
  display: inline-flex;
  position: absolute;
  width: auto;
  align-items: center;
  left: 72%;
  top: 18%;
  transform: translateX(-50%);
}
.snippet-modal-vid .play_controls {
  left: 48%;
}
.rewind-rate-col {
  margin-right: 15px;
  margin-top: -8px;
}

.play-col {
  margin: 0 15px;
  max-width: 36px;
  width: 100%;
}

.ff-rate-col {
  margin-left: 15px;
  margin-top: -8px;
}
.followup-col {
  object-fit: contain;
  /*background: url("../public/assets/img/followup.svg");*/
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 6px 0px 0px;
  align-items: center;
  top: 8px;
  right: 30%;
  position: absolute;
  font-size: 10px;
}

.accountsButton {
  align-items: center;
  font-size: 14px !important;
  font-weight: bold;
  border-radius: 20px;
  background-color: rgba(88, 89, 255, 0.1) !important;
  border: 1px solid rgba(88, 89, 255, 0.1)  !important;
  color: #0d0e10;
}

.accountsButton:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accountsButton:active,
.accountsButton:hover {
  background-color: rgba(88, 89, 255, 0.2) !important;
  border: 1px solid rgba(88, 89, 255, 0.2)  !important;
  color: #2d323c;
}

.followupButton1 {
  align-items: center;
  font-size: 11px !important;
  font-weight: bold !important;
  border-radius: 4px;
  background-color: #ffbe00;
  color: #0d0e10;
  border: 1px solid #ffbe00;
  max-height: 6px;
  object-fit: contain;
  /*background: url("../public/assets/img/followup.svg");*/
  display: inline-block;
  background-repeat: no-repeat;
  top: 8px;
  right: 17%;
  position: absolute;
  padding: 0.1rem 0.2rem 0.3rem 0.2rem;
  margin: 2px;
}
.followupButton1:focus {
  outline: none !important;
  box-shadow: none !important;
}
.followupButton1:active,
.followupButton1:hover {
  background-color: rgba(255, 190, 0, 0.6);
  border: 1px solid rgba(255, 190, 0, 0.6);
  color: #2d323c;
}

.speaker-col {
  top: 12px;
  right: 7%;
  width: 50px;
  position: absolute;
  display: inline-block;
}
.fullview-col {
  top: 15px;
  right: 4.1%;
  position: absolute;
  display: inline-block;
}

.keywordButton {
  margin-top: 8px;
  margin-right: 8px;
  border-radius: 70px;
  border: solid 0.5px #2d323c;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #202124;
  min-height: 36px !important;
  height: 36px !important;
}
.tracker-modal {
  /* width: 48%; */
  min-height: calc(100vh * 0.53) !important;
  min-width: 700px;
}
.snippet-modal {
  padding: 24px;
  overflow: hidden;
  height: auto;
}
.snippet-modal .modal-header {
  padding: 0px !important;
  margin-bottom: 15px;
  border: none;
}
.snippet-modal .modal-header .modal-title {
  display: flex;
  align-items: center;
}
.snippet-modal .modal-header .modal-title .dropdown {
  /*margin-left: 22px;*/
  font-size: 14px;
  display: flex;
  align-items: center;
}
.snippet-modal .modal-header .modal-title .dropdown button {
  color: #6f7888;
}
.snippet-modal .modal-header .modal-title .dropdown .btn {
  height: 32px !important;
  background-color: #fff;
  border: 1px solid #e1e2e9;
  border-radius: 25px;
  font-size: 14px;
}
.snippet-modal .modal-header .modal-title .dropdown .dropdown-item {
  color: #23282c !important;
  padding: 10px;
  line-height: 14px;
}
.snippet-modal .modal-header .close {
  font-size: 23px;
}
.snippet-modal .snippet-name {
  margin-top: 14px;
  font-weight: 600;
}
.snippet-modal .snippet-name input[type="text"] {
  margin-top: 2px;
  color: #202124;
  width: 400px;
  font-size: 14px;
  height: 38px;
}
.snippet-modal .snippet-desc .snippet-name-error {
  color: red;
  font-weight: 500;
}
.snippet-modal .snippet-desc {
  margin-top: 14px;
  font-weight: 600;
}
.snippet-modal .snippet-desc input[type="text"] {
  margin-top: 2px;
  color: #202124;
  width: 400px;
  font-size: 14px;
  height: 38px;
}
.snippet-modal .snippet-desc .snippet-desc-error {
  color: red;
  font-weight: 500;
}
.snippet-modal .modal-footer {
  padding: 0px;
  border: none;
}
.slider .noUi-connect {
  background: #0052cc;
}
.slider .noUi-handle {
  border: 1px solid #70738b;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #70738b, 0 3px 6px -3px #bbb;
}

.create-modal-wrapper {
  max-width: 1040px;
}
.snippet-modal .modal-footer .btn-primary {
  width: 124px;
  margin-left: 10px;
}
.snippet-modal .modal-body {
  padding: 0px;
}
.snippet-modal-slider .slider {
  padding: 0px 20px;
}
.snippet-modal-translist {
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  padding-bottom: 15px;
}
.snippet-modal-translist-vid {
  width: 60%;
}
.snippet-modal-translist-text {
  flex: 1;
  padding-left: 20px;
  height: 50%;
}
.snippet-modal-translist-text .infinite-row .dots {
  display: none;
}
.snippet-modal-translist-text .transcript-content {
  height: 380px;
}
.create-modal-wrapper .modal-footer .btn {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.snippet-modal-vid {
  margin: 0px auto;
  max-width: 600px;
  min-height: 360px;
}
.snippet-modal-vid .video-expand,
.snippet-modal-translist-vid .video-expand {
  display: none;
}
.snippet-modal-vid .player-wrapper,
.snippet-modal-translist-vid .player-wrapper {
  border-radius: 8px;
  height: 320px;
  overflow: hidden;
}
.snippet-modal-vid .video-remotepanel,
.snippet-modal-translist-vid .video-remotepanel {
  background-color: #f8f9fc;
  border-radius: 8px;
}
.snippet-modal-translist-vid .video-panel {
  margin-left: 10px;
}
.sld-range {
  display: flex;
  justify-content: space-between;
  color: #202124;
  font-size: 12px;
  margin-top: 7px;
}
.snippet-modal-translist-vid .play_controls {
  width: 212px;
  left: 48%;
}
.btn-group > .btn {
  background: #ffffff;
  border: 0.5px solid #e1e2e9;
  box-sizing: border-box;
  border-radius: 44px;
  font-size: 14px;
  line-height: 20px;
  color: #6f7888;
}
.btn-group > .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #6f7888;
  background-color: rgba(0, 82, 204, 0.1);
  border: 0.5px solid #e1e2e9;
}

.filterContainer {
  margin-top: 0px;
}
.settings-container {
  margin-left: -22px;
  max-height: calc(100vh - 20px);
  color: #202124;
}
.control_wrap .row {
  white-space: nowrap;
}

.analysis-filter-container .toggle_switch {
  width: 100%;
}

.analysis-filter-container .analysis-filter {
  display: flex;
}

.analysis-filter-container .analysis-filter button {
  margin: 10px;
}

.analysis-filter-button {
  background: none;
  border-radius: 50px;
}

.analysis-filter-button.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.analysis-tab-content {
  /* max-height: 500px; */
  height: calc(100vh - 120px);
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}

.topic-breakdown-title {
  font-weight: normal;
  margin-bottom: 4px;
}

.subtopic_brkdwn_row {
  border: 1px solid rgba(0 0 0 / 20%);
  padding: 10px;
  border-radius: 20px;
}

.topic-breakdown-topic-cont .topic {
  color: #0052cc;
  font-weight: normal;
}

.topic-breakdown-mentions-cont .mentions {
  color: #0052cc;
  font-weight: normal;
}

.topic-breakdown-topic-cont {
  margin-right: 12px;
}

.general-stats-dropdown img {
  height: 25px;
}

.filter_icn button {
  border: none;
  background: none !important;
  color: #000 !important;
}

.filter_icn button:active,
.filter_icn button:hover,
.filter_icn button:focus {
  border: none;
  background: none !important;
  outline: none;
  box-shadow: 0px 0px 0px #fff !important;
}

.topic-breakdown-title,
.topic-breakdown-topic-cont,
.topic-breakdown-mentions-cont {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topic-breakdown-topic-cont,
.topic-breakdown-mentions-cont {
  color: #2d323c;
}
.dropDownMain .css-1pahdxg-control,
.dropDownMain css-yk16xz-control {
  height: 25px;
  overflow-y: auto;
}
.css-yk16xz-control {
  height: 25px;
  overflow-y: auto;
}
.dropDownMain div {
  border: none;
}
.dropDownMain svg {
  fill: unset;
}
.trascript-action-container {
  margin: 5px 0;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
}
.new-tag {
  position: absolute;
  top: -0.3rem;
  left: 8.7rem;
  background-color: #FFD954;
  color: black;
  border-radius: 8px;
  padding: 0.2em 0.5em;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.03em;
}

.new-tag-followup {
  position: absolute;
  top: -8px;
  left: -0.3em;
  color: green;
  padding: 0.2em;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.03em;
}
.new{
  position: absolute;
  top: -13px;
  left: 0.35em;
  color: white;
  background-color: rgb(86, 1, 249);
  border-radius: 8px;
  padding: 0.1em 0.25em;
  font-size: 9px;
  font-weight: bolder;
  letter-spacing: -0.03em;
}

.topBarButton {
  font-weight: 700 !important;
  font-size: 10px !important;
  margin: 0.2rem;
  border-radius: 12px;
  max-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2D323C;
  background-color: rgba(88, 89, 255, 0.1) !important;
  border: 1px solid rgba(88, 89, 255, 0.1)  !important;
}
.topBarButton:hover {
  color: #2d323c !important;
  background-color: rgba(88, 89, 255, 0.2) !important;
  border: 1px solid rgba(88, 89, 255, 0.2)  !important;
  box-shadow: none !important
}
.topBarButton:active {
  color: #2d323c !important;
  background-color: rgba(88, 89, 255, 0.2) !important;
  border: 1px solid rgba(88, 89, 255, 0.2)  !important;
  box-shadow: none !important
}

.topBarCalendar {
  width: 5.4rem !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  /*padding: 0.2rem 0.2rem;*/
  border-radius: 12px;
  max-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d323c !important;
  background-color: rgba(88, 89, 255, 0.1) !important;
  border: 1px solid rgba(88, 89, 255, 0.1)  !important;
}
.topBarCalendar:hover {
  background-color: rgba(88, 89, 255, 0.2) !important;
  border: 1px solid rgba(88, 89, 255, 0.2)  !important;
  box-shadow: none !important
}
.topBarCalendar:active {
  background-color: rgba(88, 89, 255, 0.2) !important;
  border: 1px solid rgba(88, 89, 255, 0.2)  !important;
  box-shadow: none !important
}

.vertical-line {
  margin-left: -16px;
  position: absolute;
  /* border: 10px; */
  background-color: #606060;
  /* transform: rotate(90deg); */
  width: 1px;
  /* height: auto !important; */
}

.vertical-pins {
  top: -15px;
  position: absolute;
  background-color: #0052cc;
  width: 1px;
}
.vertical-pins::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 8px;
  border-radius: 100%;
  top: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -4px;
  background-color: #0052cc;
}
.topic-accordion .card,
.topic-accordion .list-group-item {
  border: 0px !important;
  margin: 0px !important;
}
.topic-accordion-header:hover {
  border: solid 0.5px #0052cc !important;
}

.topic-accordion .list-group-item {
  padding: 2px 5px;
}
.topic-accordion .card {
  border: 0px !important;
}
.topic-accordion-header {
  height: 68px !important;
  border-radius: 8px !important;
  /* border: solid 0.5px #0052cc !important;*/
  background-color: rgba(0, 82, 204, 0.05) !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topic-accordion-header-name {
  height: 21px;
  width: 75%;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  white-space: nowrap;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2d323c !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /*text-transform: capitalize;*/
}
.topic-accordion-total {
  width: 56px;
  height: 21px;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #202124 !important;
}
.topic-accordion-total .label {
  color: #202124 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: inherit;
}

.topic-accordion-total .total {
  font-weight: 600 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0052cc !important;
}
.header-chart-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  position: relative;
  margin-top: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-chart-text {
  /* font-family: inherit; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  display: inline-block;
  top: -10px;
  position: relative;
  left: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipse-4 {
  width: 4px;
  height: 4px;
  background-color: #a9acbd;
  border-radius: 50%;
}

.sub-topic .topic-accordion-header {
  width: 100% !important;
  height: 44px !important;
  border-radius: 40px !important;
  border: solid 1px #e1e2e9 !important;
  background-color: #ffffff !important;
}

.sub-topic .topic-accordion-header-z {
  width: 100% !important;
  height: 44px !important;
  border-radius: 40px !important;
  border: solid 1px rgba(255, 153, 0, 0.5) !important;
  background-color: rgba(255, 153, 0, 0.1) !important;
  /* opacity: 0.5; */
}

.sub-topic .list-group-item {
  padding: 5px 1px !important;
}
.sub-topic .accordian-row.card-header .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.sub-topic .header-chart-wrapper {
  height: 26px;
  width: 26px;
  display: inline-block;
  position: relative;
  margin-top: 5px;
}
.sub-topic .accordiomItem {
  box-shadow: 0px 0px 0px 0px #e0e7eb !important;
}
.sub-topic .topic-accordion-header-name {
  padding-left: 0px !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #202124 !important;
  text-transform: capitalize;
  margin-top: 6px;
}
.sub-topic .topic-accordion-total {
  margin-top: 6px;
}
.sub-topic .header-chart-text {
  top: -5px;
}
.sub-topic .transcript-row {
  padding-top: 1px;
  padding-bottom: 0px;
  padding-left: 1px;
  margin-left: 1px !important;
  margin-right: 4px;
}
.sub-topic .transcriptText {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202124;
  padding-left: 0px;
}
.sub-topic .transcript-row-text {
  padding-left: 10px;
  margin-left: 1px;
  margin-right: 2px;
}
.sub-topic .transcriptName {
  padding-top: 3px;
}
.transcript-content .header-profile-icon {
  background: transparent;
  left: 10px;
  top: 0px;
  position: relative;
  width: 24px !important;
  height: 24px !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: 20px !important;
}

li.active.list-group-item-action.list-group-item
div.transcript-row.row
div.header-profile-icon.header-icon {
  background: var(--topBannerColor2);
}

.dots {
  background-image: url("../public/assets/img/dots.svg") !important;
  width: 20px !important;
  height: 20px !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: 20px !important;
  float: right;
  top: -10px;
  margin-right: 15px;
}

.video-react-current-time,
.video-react-time-divider,
.video-react-duration,
.video-react-play-control,
.video-react-menu-button-inline {
  display: none;
}

.video-react-play-control.video-react-control.video-react-button.video-react-playing {
  display: none;
}
.video-react-icon-fullscreen.video-react-fullscreen-control.video-react-control.video-react-button.video-react-icon {
  display: none;
}
.snippet-modal-translist .video-react .video-react-progress-control {
  display: none;
}
/*menu*/
.meetingmenu {
  width: 12px;
  height: 22px;
  object-fit: contain;
  top: 0px;
  left: 90%;
  position: absolute;
  opacity: 0;
}

.transcriptmenu {
  width: auto;
  text-align: right;
  opacity: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
  float: right;
  white-space: nowrap;
}
.transcriptmenu img {
  margin-right: 15px;
}

/* .transcriptmenu svg{
  fill: #495057;
  filter: grayscale(1);
}
.transcriptmenu svg:hover {
  fill: #0052CC;
  filter: initial;
}
.transcriptmenu svg:hover path{
  fill: #0052CC;
  filter: initial;
}
.transcriptmenu svg.snippet:hover{
  fill: #0052CC;
  filter: brightness(-1) !important;
}
.transcriptmenu svg.snippet:hover path{
  fill: #0052CC;
} */

.transcriptmenu a.nav-link {
  padding: 0;
  height: 12px;
}

.transcriptmenu a.nav-link .dots {
  position: absolute;
  height: 12px !important;
  width: 24px !important;
  top: 0;
}

.list-group-item-action:hover .transcriptmenu {
  opacity: 1;
}

.sub-menu {
  background-color: #2d323c;
  border: 1px solid;
  border-radius: 4px;
  align-items: center;
}
.deals-sub-menu {
  background-color: #2d323c;
  border: 1px solid;
  border-radius: 4px;
  align-items: center;
}
.playlists-sub-menu {
  background-color: #2d323c;
  border: 1px solid;
  border-radius: 4px;
  align-items: center;
}
.sharedto-icon {
  background: white;
  left: 10px;
  top: 0px;
  position: relative;
  /*background-image: url("../public/assets/img/shareto.svg") !important;*/
  width: 20px !important;
  height: 20px !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: 20px !important;
}
.sharedto,
.dropdown-item {
  font-size: 12px;
  line-height: 21px;
  color: #fff !important;
  padding-left: 12px;
  align-items: center;
}

.shareto-active {
  background-color: rgb(114 114 246);
}
.shareto-active:hover {
  background-color: rgb(134 134 250) !important;
}

.sub-menu .dropdown-item:hover,
.sub-menu .dropdown-item:focus {
  color: #181b1e;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}
.disable-link {
  pointer-events: none;
  color: grey !important;
  filter: grayscale(100%) saturate(240%);
}

/*sign up*/
.value-time-Boost-productivity {
  font-family: inherit;
  font-size: 2.5rem;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grandParentContaniner {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
}

.Rectangle-140 {
  width: auto;
  height: 85vh;
  padding: 5px;
  background-color: #ffffff;
  align-items: center;
  margin: auto !important;
  vertical-align: middle;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.parentContainer {
  vertical-align: middle;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.error {
  border: 1px solid red;
}
.parentContainer .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  /*padding-top: 30px;*/
}
.parentContainer .loginForm {
  padding-top: 60px;
}
.parentContainer form {
  padding-top: 10px;
}
.parentContainer label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.parentContainer button {
  margin-top: 26px;
}

.No-credit-card-required-Cancel-Anytime {
  font-family: Caveat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: normal;
  color: #2a2f5a;
}

.noaccount {
  position: absolute;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  color: #2d323c;
}
.login-title {
  position: relative;
  top: 60px;
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  font-stretch: expanded;
  color: #ffffff;
  text-align: center;
}
.login-info {
  width: 531px !important;
  height: 670px !important;
  background-image: url(../public/assets/img/login-info.svg) !important;
  background-repeat: no-repeat !important;
  position: absolute;
  background-size: 580px;
  margin-left: -20px;
  margin-top: 40px;
}
.quotes {
  width: 248px !important;
  height: 100px !important;
  background-image: url(../public/assets/img/quotes.svg) !important;
  background-repeat: no-repeat !important;
  position: relative;
  background-size: 140px;
  margin-left: 20px;
  margin-top: 40px;
}
.parentContainer label {
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #2d323c;
}
.parentContainer input {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #000;
}
.text-muted {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: #6c757d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loginData {
  width: 50%;
  padding-top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mask1 {
  background-image: url(../public/assets/img/mask1.svg) !important;
  width: 100% !important;
  height: 460px !important;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: 80% !important;
  display: block;
  margin: auto;
  max-height: 460px !important;
  max-width: 800px !important;
}
.mask {
  background-image: url(../public/assets/img/mask.svg) !important;
  width: 100% !important;
  height: 460px !important;
  background-repeat: no-repeat !important;
  /*margin-top: -8%;*/
  background-position: center;
  background-size: 80% !important;
  display: block;
  margin: auto;
  max-height: 460px !important;
  max-width: 800px !important;
}
.Rafiki-sits-like-a-fly-on-the-Wall-and-makes-recom {
  font-family: Muli;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  color: #ffffff;
  margin-top: -2%;
  margin-bottom: 20%;
  text-align: center;
  padding: 0 50px;
}
.arrowmask {
  background-image: url(../public/assets/img/arrowmask.svg) !important;
  width: 298px !important;
  height: 800px !important;
  background-repeat: no-repeat !important;
  /* margin-top: 80px; */
  background-size: 400px;
  margin-left: 500px;
  margin-top: -1250px;
  /* margin-top: -550px; */
  position: absolute;
}
.star {
  background-image: url(../public/assets/img/star.svg) !important;
  width: 184px !important;
  height: 23px !important;
  background-repeat: no-repeat !important;
  /* margin-top: 80px; */
  background-size: 134px;
  margin-left: 205px;
  position: absolute;
  display: none;
}

.quote-name {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  color: #ffffff;
  position: relative;
  top: 20px;
  left: 219px;
  display: none;
}

.quote-name-title {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  color: #ffffff;
  position: relative;
  top: 20px;
  left: 202px;
  display: none;
}

.header-profile-icon li {
  list-style: none;
}
.header-profile-icon {
  margin-top: 0px;
  width: 30px;
  height: 30px;
  background-color: inherit;
  border-radius: 50%;
}
.record-action-container {
  display: grid;
  margin-top: 12px;
  grid-template-columns: 40px 270px 40px;
}

.filter-panel .card {
  border: 0;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.contentPanelHeaderDeals {
  margin-bottom: -11px;
  flex-wrap: nowrap;
  white-space: nowrap;
  background-color: var(--topBannerColor05);
}

.contentPanelHeaderPlaylist {
  flex-wrap: nowrap;
  /*white-space: nowrap;*/
  padding: 5px 5px 20px 35px;
  margin: 0px 10px -11px 0px;
}

.contentPanelHeader {
  margin-bottom: -11px;
  flex-wrap: nowrap;
  white-space: nowrap;
  background-color: white;
  /*background-color: var(--topBannerColor07);*/
  /*border-bottom: solid 1px #e1e1e5;*/
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.1);
  margin-bottom: 0px;
}
.nav-block-togglable {
  display: inline-block;
  position: absolute;
  right: 5px;
  cursor: pointer;
  top: -10px;
  transform: rotate(90deg);
}
.cp_links .navbar-nav {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.cp_links .navbar-nav > li.nav-item {
  white-space: nowrap;
  height: 50px;
  position: relative;
  justify-content: flex-end;
}

.cp_links .navbar-nav > li.nav-item .meeting-navlink {
  cursor: pointer;
}

.cp_links .navbar-nav > li.nav-item:hover {
  color: #0052cc;
}

.app-tooltip {
  position: absolute;
  top: -22px;
  opacity: 0;
  transition: 0.45s;
  transform: translateX(-50%);
  background-color: #0e1318;
  color: white;
  z-index: 1;
  font-size: 13px;
  border-radius: 4px;
  padding: 4px 10px;
  margin-top: 18px;
  left: 50%;
  pointer-events: none;
  transform-origin: center;
}
.app-tooltip:after {
  content: "";
  border-style: solid;
  border-color: transparent;
  border-top-color: #0e1318;
  border-width: 0.5em 0.5em 0 0.5em;
  position: absolute;
  transform: translateX(-50%);
  bottom: -6px;
  left: 50%;
}
.cp_links .navbar-nav > li.nav-item .meeting-navlink:hover ~ .app-tooltip {
  opacity: 1;
  top: -26px;
}

.cp_links .navbar-nav .nav-link {
  justify-content: center;
}

.panel_wrap {
  /*padding: 0px 1.2em 0px 5px;*/
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: calc(100vh - 20%);
}

.panel_wraps {
  padding: 0px 2.2em 0px 5px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
}

.panel_wraps_nopadding {
  padding: 0px 0px 0px 5px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
}

.panelContenthide {
  height: calc(100vh - 108px);
}

.toggle_bar {
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.heading_wrap {
  padding-left: 15px;
  height: 30px;
}
.full_toggle_deal {
  position: absolute;
  left: 8px;
  display: inline-block;
  width: 16px;
  z-index: 9;
  padding: 15px 0px 0px 2px;
}

.full_toggle {
  position: absolute;
  left: 4px;
  display: inline-block;
  width: 16px;
  z-index: 9;
  padding: 1rem 0px 0px 2px;
}
.btn_wrap_notes {
  bottom: 20%;
  width: 100%;
  position: absolute;
  /*float: right;*/
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn_wrap {
  bottom: 0px;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: white;
  /*-ms-transform: translateY(-50%);*/
  /*transform: translateY(-50%);*/
}
.toolbar {
  display: flex;
  align-items: center;
  padding-top: 2px;
}
.actionTab {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
}

.actionTab .tab-border {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionTab2 .nav-item:hover {
  background-color: var(--topBannerColor09) !important;
  font-weight: bold;
  border-radius: 8px;
}

.actionTab2 .nav-item:active {
  background-color: var(--topBannerColor09) !important;
  border-bottom: 3px solid var(--mainColor) !important;
  font-weight: bold;
  border-radius: 8px;
}
/*===================Analysis Tab Css===================*/

.analysis_head {
  margin-bottom: 8px;
  position: relative;
  display: flex;
  font-weight: 500;
}
.analysis_head .meeting-third-row-vector {
  position: relative;
  left: auto;
  top: 4px;
  margin-left: 10px;
}
.analysis_head .meeting-third-row-vector:hover ~ .app-tooltip {
  opacity: 1;
  top: -28px;
}

.analysis_container .img-24 {
  margin-left: 10px;
}

.analysis_container > .row > .overview-container {
  margin: 0 11px 5px 11px;
  border: 1px solid #f1f2f6;
  padding: 15px 0 15px 0px;
}

.analysis_container .actionTab.nav-tabs > li {
  margin-bottom: 24px;
}

.analysis_container .actionTab > li > a {
  padding: 0.5rem 0.5rem !important;
  height: 36px !important;
  border-radius: 4px;
  min-width: 50px;
}

.analysis_container .actionTab > li span {
  margin-left: 0px !important;
  opacity: 1;
  visibility: visible;
  width: auto;
}

.analysis_container .actionTab > li span img {
  position: absolute;
  transition: 0.45s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.analysis_container .actionTab > li .nav-link .blue-img {
  opacity: 0;
}
.analysis_container .actionTab > li .nav-link.active .blue-img {
  opacity: 1;
}
.analysis_container .actionTab > li .nav-link.active .gray-img {
  opacity: 0;
}

.analysis_container .wdth100.pos_rel {
  margin: 0 2 0 0;
}

.analysis_container .actionTab.nav-tabs > li > a {
  background-color: transparent !important;
}

.analysis_container .actionTab.nav-tabs > li > a.active.nav-link {
  /*background-color: #f2f6fc !important;*/
  background-color: white !important;
}

.analysis_container .analysis-filter-container {
  padding: 0 15px;
}

.analysis_container .analysis-filter-container .toggle_switch {
  width: 130px;
  display: inline-flex !important;
}

.analysis_container .analysis-filter-container .switch {
  margin-right: 19px;
  width: 33px;
  height: 20px;
}

.analysis_container .analysis-filter-container .switch-3d .switch-slider {
  border: none;
}

.analysis_container .analysis-filter-container .switch-3d .switch-slider::before {
  top: 0px;
  width: 19.8px;
  height: 20px;
}

.switch-input:checked ~ .switch-slider::before {
  transform: translateX(17px);
}

.analysis_container .analysis-filter-container .analysis-filter {
  align-items: flex-start;
  padding: 0 4px;
}

.tabs_btn {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% - 130px);
}

.analysis_tab_row {
  flex-wrap: nowrap;
}

.analysis_tab_row > .col-md-2 {
  min-width: fit-content;
  padding-top: 20px;
}

.analysis_container .analysis-filter-container .analysis-filter button {
  margin: 0 0.6% 10px 0.6%;
  font-size: 14px;
  height: 32px !important;
  min-width: fit-content;
}

.analysis_container .trascript-action-container {
  margin: 5px 0 10px 0;
  width: 130px;
}

.topic-star_tabs {
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 0px;
  height: calc(100vh - 340px);
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  padding-right: 2px;
}
.topic-stars_tabs {
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 0px;
  height: calc(100vh - 300px);
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  padding-right: 2px;
}
.topic-tracks {
  margin-top: 3px;
  margin-left: 0px;
  margin-right: -10px;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

.topic-breakdown {
  margin-top: 3px;
  margin-left: 0px;
  margin-right: -10px;
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}
.video-panel-bottom.topic_tabs {
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 0px;
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}
.topic-breakdown_tabs {
  margin-top: 3px;
  margin-left: 0px;
  margin-right: -10px;
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}
.analysis-tab-content .transcript-content {
  height: calc(100vh - 382px);
}

.topic_tabs .timebar {
  padding-left: 15px;
}

.topic_tabs .timeBar-container {
  padding-bottom: 0;
  margin-bottom: 17.6px;
}

.topic_tabs .timeBar-container .font-12 {
  padding-top: 0px !important;
  color: #202124;
  margin-bottom: 8.8px;
}

.topic_tabs .timeBar-container .font-12 > span {
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px;
}

.lineGraphContent .modal-content {
  height: 500px;
  width: 750px;
}

.lineGraphContent {
  max-width: 750px;
  top: 150px;
}

.topic_tabs .bar-row {
  height: 8.8px;
  border-radius: 40px;
}

.topic_brkdwn_row {
  display: flex;
  margin-bottom: 22px;
  width: 100%;
  cursor: pointer;
  align-items: center;
}

.topic_name_col {
  display: inline-block;
  width: 150px;
  padding-right: 5px;
}

.topic_name_col.align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.topic_chart_col {
  width: 85px;
  flex-shrink: 0;
}

.topic_chart_col p {
  font-size: 12px;
  margin-bottom: 0px;
}

.pie_chart_col {
  display: inline-block;
  position: relative;
  width: 80px;
}

.pie_chart_col .chartjs-size-monitor {
  width: 40px;
  height: 40px;
}

.perc_line {
  padding: 0;
  width: 260px;
  background: #f7f3f3 none repeat scroll 0% 0%;
  height: 12px;
  border-radius: 6px;
  margin-right: 10px;
}

.perc_bar {
  height: 12px;
  background: #5601f9 !important;
  border-radius: 6px;
}

.graph_row {
  display: flex;
  margin-top: 1px;
  row-gap: 5px;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}

.graph_row:after {
  content: "";
  position: absolute;
  height: 100%;
  border-right: 2px dashed rgba(120, 115, 137, 0.1);
  left: 50%;
  transform: translateX(-50%);
}

.graph_col {
  margin: 5px;
  width: 47%;
  min-height: fit-content;
  border: solid 1px rgba(120, 115, 137, 0.1);
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.1);
  border-radius: 16px;
  position: relative;
}
.analysis_boxes {
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.1);
  border: 1px solid #e4e5ee;
  padding: 0.5em;
  border-radius: 8px;
}

.graph_head {
  padding: 8px 20px 8px 20px;
  border-bottom: solid 1px rgba(120, 115, 137, 0.1);
  position: relative;
}

.graph_head > h3,
.graph_head h4,
.graph_head p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter_icn {
  position: absolute;
  top: 20px;
  right: 15px;
  color: #787389;
}

.graph_head p {
  margin-left: 12px;
  margin-bottom: 0px;
}

.line_chart {
  position: relative;
  height: max-content;
  clear: both;
}

.analysis_tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  flex-shrink: 0;
  flex-grow: 1;
}

.analysis_tab_col {
  width: 16.6%;
  padding: 0 0.5%;
  margin-top: -5px;
  margin-bottom: -5px;
  min-width: fit-content;
}

.circular-lmg {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
}
.rounded-img {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 4px;
}
rounded-img {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 12px;
}
.circular-lmg24 {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
}
.circle {
  /*background-color: rgb(251, 50, 84);*/
  color: white;
  font-weight: 600;
  width: 20px;
  height: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  font-size: 12px;
  border-radius: 16px;
}
.circular-lmg img {
  width: auto;
  height: 100%;
  background-color: rgba(0, 84, 204, 0.2);
}
.circular-lmg div {
  text-align: center;
  /*width: 100%;*/
  height: 100%;
  padding-top: 4px;
  font-size: revert;
  color: white;
  font-weight: bold;
  background-color: rgba(0, 84, 204, 0.2);
}
.circular-image {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  top: -5px;
  background-color: rgba(0, 84, 204, 0.2);
}

.circular-image-comment{
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  top: 2px;
  background-color: rgba(0, 84, 204, 0.2);
}
.circular-image-comment img {
  width: auto;
  height: 100%;
}
.circular-image-comment div {
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 6px;
  color: white;
  font-weight: bold;
  font-size: 10px;
}

.circular-image-summary {
  /* display: inline-block; */
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  top: -5px;
}

.circular-image img {
  width: auto;
  height: 100%;
}
.circular-image div {
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 6px;
  color: white;
  font-weight: bold;
  font-size: 10px;
}
.popover-buttonRole,
.popover-buttonRole:hover,
.popover-buttonRole:active,
.popover-buttonRole:focus {
  font-size: 10px !important;
  font-weight: 600 !important;
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  border: none;
  color: rgba(74, 21, 75, 1);
  border-radius: 50%;
  background-color: rgba(74, 21, 75, 0.15);
  padding: 0px 4px;
  margin-left: 8px;
  box-shadow: 0 0 0 0;
}

.popover-button,
.popover-button:hover,
.popover-button:active,
.popover-button:focus {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.45;
  width: 36px;
  height: 36px !important;
  border: none;
  color: #0052cc;
  border-radius: 20px;
  background-color: rgba(0, 82, 204, 0.15);
  padding: 0 5px;
  margin-left: 7px;
  box-shadow: 0 0 0 0;
}
.popover-button:active {
  color: #0052cc;
  background-color: #acb5bc;
  border-color: #a5aeb7;
}

.popover-button:active {
  color: #0052cc;
  background-color: #acb5bc;
  border-color: #a5aeb7;
}

.tooltip-inner {
  font-size: 13px;
  width: auto;
}
.nothing_to_display {
  /*display: inline-block;*/
  justify-content: center;
  height: 100vh;
  width: 100vh;
  align-items: center;
  margin: 10px;
  background-color: #ffffff;
}
.nothing_to_display h4 {
  margin: 0;
}

.not_found {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
  margin: 20px;
  font-size: 14px;
}
.not_found p {
  margin: 0;
  font-size: 14px;
}

.snippet-name-error {
  font-size: 12px;
  color: brown;
}

.h100 {
  height: 100vh;
}

#root {
  /*--mainColor: #4a154b;*/
  /*--transcriptColor: rgba(74, 20, 91, 0.01);*/
  /*--topBannerColor1: rgba(74, 20, 91, 0.1);*/
  /*--topBannerColor2: rgba(74, 20, 91, 0.2);*/
  /*--topBannerColor01: rgba(74, 20, 91, 0.01);*/
  /*--topBannerColor02: rgba(74, 20, 91, 0.02);*/
  /*--topBannerColor04: rgba(74, 20, 91, 0.04);*/
  /*--topBannerColor05: rgba(74, 20, 91, 0.05);*/
  /*--topBannerColor07: rgba(74, 20, 91, 0.07);*/
  /*--topBannerColor09: rgba(74, 20, 91, 0.09);*/
  /*--topBannerColor9: rgba(74, 20, 91, 0.9);*/

  --mainColor: rgba(88, 89, 255, 1);
  --transcriptColor: rgba(88, 89, 255, 0.01);
  --topBannerColor1: rgba(88, 89, 255, 0.1);
  --topBannerColor2: rgba(88, 89, 255, 0.2);
  --topBannerColor01: rgba(88, 89, 255, 0.01);
  --topBannerColor02: rgba(88, 89, 255, 0.02);
  --topBannerColor04: rgba(88, 89, 255, 0.04);
  --topBannerColor05: rgba(88, 89, 255, 0.05);
  --topBannerColor07: rgba(88, 89, 255, 0.07);
  --topBannerColor09: rgba(88, 89, 255, 0.09);
  --topBannerColor9: rgba(88, 89, 255, 0.9);
}


#root .meeting-dropdown .dropdown-menu {
  top: 0 !important;
  color: white !important;
}

.add-to-list .modal-content {
  padding: 5px;
}

.leftPanelTab .card {
  border: 0px solid;
}

/*input[type="checkbox"] {*/
/*  appearance: auto !important;*/
/*  !*outline: #e1e1e5 !important;*!*/
/*  border: 0.5px solid #e1e1e5 !important;*/
/*}*/
input[type="radio"] {
  appearance: auto !important;
}

.input_range {
  height: 36px;
}

.input-range__slider {
  background: #0655cc;
  border: 1px solid #0655cc;
}
.input-range__track {
  background: #787389;
}

.input-range__track--active {
  background: #0655cc;
}

.input-range__label {
  display: block;
}

.range_label {
  width: 100%;
  text-align: center;
  margin-bottom: 6px;
  padding-top: 0px;
  color: #a9acbd;
  font-size: 12px;
}

.middlepanel .auto-scroll-btn {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 18px;
  border: none;
  border-radius: 16px;
  outline: none;
  padding: 3px 15px;
  color: #ffffff;
  font-weight: 500;
  background-color: rgba(0, 82, 204);
}

.auto-scroll-btn:hover {
  background-color: rgba(0, 82, 204, 0.1);
  color: #0052cc;
}

/*.microsoftRegister {*/
/*  padding-top: 10px;*/
/*  cursor: pointer;*/
/*}*/

@media only screen and (min-width: 310px) and (max-width: 767px) {
  .microsoftSignUp {
    max-width: 90% !important;
  }

  .text-muted {
    padding-left: 5px;
    word-break: normal;
    white-space: pre-wrap;
  }
  .value-time-Boost-productivity {
    padding-left: 5px;
  }
  .loginData {
    width: auto;
    padding-top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }
  .haveAccount {
    /*margin-left: -20px !important;*/
    font-size: 16px !important;
    font-weight: 600;
  }
  .header-menu {
    width: auto;
  }

  .logo {
    flex-shrink: 0;
    white-space: nowrap;
  }

  .header_icons {
    gap: 6px;
    margin: 0 10px 0px auto;
  }

  /* .contentPanelHeader {
    flex-wrap: nowrap;
  } */

  /* .msg_heading,
  .cp_links {
    width: auto;
    padding: 0px;
  } */

  .msg_heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 15px;
  }

  .cp_links .navbar-nav > li.nav-item {
    padding: 0.8rem !important;
  }

  .trascript-action-container {
    gap: 5px;
  }

  .switch_btn button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px !important;
  }
}

@media only screen and (max-width: 1420px) {
  .overview-container .row .col-lg-3 {
    /* width: 50% !important;
    flex: 0 0 50%;
    max-width: 50%; */
    white-space: normal;
  }

  .header_icons {
    gap: 10px;
  }
}

@media only screen and (max-height: 786px) {
  .player-wrapper {
    width: 100%;
    height: 45vh;
  }
  /* .video-panel-bottom {
    height: calc(100vh - 628px);
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .header-menu {
    margin-left: 14px;
  }
}

.content-not-expaned .rightpanel,
.rightpanel.panel-open {
  flex-grow: 1;
  /*width: min-content !important;*/
}

.content-not-expaned .transcriptsPanel,
.transcriptsPanel.panel-open {
  /*width: min-content !important;*/
}

@media only screen and (max-width: 1250px) {
  /* styles for browsers smaller than 1250px; */
  .middlepanel {
    flex-shrink: 0;
    max-width: 275px;
    width: 100%;
  }

  .right-close .middlepanel {
    max-width: 100%;
  }
}

.followupPage {
  margin: 10px 10px 0px -5px;
  width: 60%;
  height: calc(100vh - 80px);
  display: block;
  flex-shrink: 0;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

@media only screen and (max-width: 899px) {
  /*browser window is 899px or smaller,*/
  /* .header-menu-navbar-nav .navbar-nav .nav-item{
      padding: 0 0.6rem !important;
    } */
  .page-title {
    font-size: 0.9em;
  }
  .meeting-second-row-time {
    display: none;
  }

  .cp_links .navbar-nav {
    float: left !important;
  }

  .cp_links .navbar-nav .nav-link {
    justify-content: flex-start;
  }

  .toggle_switch {
    display: none;
  }

  .trans-search {
    max-width: 420px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .trans-search-input {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .logo {
    background: url("../public/assets/img/logo-icon.svg") no-repeat center;
    background-size: contain;
    height: 36px;
    max-width: 36px;
    white-space: nowrap;
    cursor: pointer;
  }

  .header_icons {
    padding-left: 10px;
  }
  .header-icon {
    width: 24px !important;
    height: 24px !important;
  }
  .header-profile-icon {
    margin-bottom: 23px !important;
  }
  .header-profile-icon.no-margin {
    margin-bottom: 0px !important;
  }

  .header-icon img {
    max-width: 100%;
  }

  .uploadMeeting {
    display: none;
  }

  .more {
    display: none;
  }

  /* .header-menu-navbar-nav .navbar-nav .nav-item{
    padding: 0 0.9rem !important;
  } */

  .nav-search {
    padding: 0 0px 0 25px;
  }

  .contentPanelHeader .navbar-nav .align-center {
    font-size: 11px;
  }

  .topic_chart_col {
    min-width: fit-content;
  }
}

@media (max-width: 765px) {
  .actionTab .tab-border > span {
    opacity: 0;
    visibility: hidden;
    width: 0px;
  }
}

/* start of meetings page screen size handling */

.transcript-comments-dropdown {
  display: none;
}

@media only screen and (max-width: 1425px) {
  .meetings-open .topBarCalendar.hideCalendarButton {
    /*font-size: 0 !important;*/
    background-color: #ffffff00 !important;
    border: none !important;
    width: fit-content !important;
    margin: 0px !important;
  }

  .hideText {
    display: none;
    background-color: #ffffff00 !important;
    border: none !important;
    width: fit-content !important;
    margin: 0px !important;
  }

  .meetings-open .topBarRightButtonGroup {
    margin-left: initial;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 1400px) {
  .content-not-expaned .low-res-show {
    display: inline !important;
  }

  .content-not-expaned .small-d-none {
    display: none;
  }
  /* .content-not-expaned .recording-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .content-not-expaned .conversation-metrics-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .content-not-expaned .topic-tracks-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .content-not-expaned .topic-breakdown-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  } */
}

@media only screen and (max-width: 1480px) {

  .content-not-expaned .video-remotepanel .rewind-rate-col,
  .content-not-expaned .video-remotepanel .ff-rate-col {
    display: none !important;
  }

  .content-not-expaned .video-remotepanel .comment {
    margin-left: 2px;
  }
  .content-not-expaned .video-remotepanel .snippet {
    margin-left: 5px;
  }
  .content-not-expaned .video-remotepanel .shareLink {
    margin-left: -6px;
  }
  .content-not-expaned .video-remotepanel .play-col {
    margin: 0 8px;
  }

}

@media only screen and (max-width: 1260px) {
  .content-not-expaned .video-remotepanel .comment,
  .content-not-expaned .video-remotepanel .snippet,
  .content-not-expaned .video-remotepanel .shareLink {
    display: none !important;
  }

}

@media only screen and (max-width: 1400px) {

  .video-remotepanel .rewind-rate-col, .video-remotepanel .ff-rate-col {
    display: none !important;
  }

  .video-remotepanel .comment {
    margin-left: 2px !important;
  }
  .video-remotepanel .snippet {
    margin-left: 5px !important;
  }
  .video-remotepanel .shareLink {
    margin-left: -6px !important;
  }
  .play-col {
    margin: 0 8px;
  }

}

@media only screen and (max-width: 1275px) {
  .meetings-open .topBarCalendar.upgradeButton{
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .transcript-comments-buttons {
    display: none;
  }

  .transcript-comments-dropdown {
    display: initial;
    padding-right: calc(500px - 45vw);
  }
}

@media only screen and (max-width: 1650px) {
  .content-not-expaned .transcript-comments-buttons {
    display: none;
  }
  .content-not-expaned .transcript-comments-dropdown{
    display: initial;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1250px) {
  .meetings-open .topBarCalendar {
    display: none;
  }

  .content-not-expaned .transcript-comments-dropdown{
    display: initial;
    padding-right: calc(1250px - 100vw);
  }
}

.icon-button-list-dropdown {
  position: absolute;
  top: -4px;
  padding-left: max(calc(100vw - 120px), 450px);
  display: none;
}

.content-not-expaned .icon-button-list-dropdown {
  padding-left: max(calc(100vw - 490px), 450px);
}

.show-1400 {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .hide-1400 {
    display: none;
  }
  .show-1400 {
    display: initial;
  }
}

/* end of meetings page screen size handling */

@media only screen and (max-width: 1100px) {
  .cp_links .align-center .disable-link {
    display: none;
  }

  .n-disp {
    display: none;
    margin-left: 8px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .tooltip {
    display: none !important;
  }
}

@media only screen and (max-width: 736px) {
  .nav-search-outer {
    flex-shrink: 1;
    min-width: 250px;
  }

  .header-menu-navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header-menu-navbar-nav .navbar-nav {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 52px;
    background: #efefef;
    height: 45px;
    z-index: 9;
    width: 100%;
  }

  .header-menu-navbar-nav .navbar-nav.active {
    display: flex;
    justify-content: center;
  }

  .header-menu-navbar-nav .navbar-nav > li {
    font-size: 12px;
    padding: 0 0.4rem !important;
  }

  .header-menu-navbar-nav .navbar-nav > li > a {
    padding-right: 0px;
  }

  .header-menu {
    display: inline-flex;
    flex-shrink: 0;
  }

  .toggle_bar {
    opacity: 1;
    visibility: visible;
    display: inline-block;
    width: 28px;
    height: 28px;
    transition: 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 1050px) {
  .low-res-show {
    display: inline !important;
  }

  .small-d-none {
    display: none;
  }


  /* .recording-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .conversation-metrics-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .topic-tracks-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .topic-breakdown-low-res-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: url(../public/assets/img/addToListenLater.svg);
    display: inline-block;
    background-repeat: no-repeat;
    font-size: 0;
  } */


  .dashboard-col,
  .rate-col,
  .speaker-col,
  .fullview-col {
    display: none;
  }

  .middle-close .rightpanel .dashboard-col,
  .middle-close .rightpanel .rate-col,
  .middle-close .rightpanel .speaker-col,
  .middle-close .rightpanel .fullview-col {
    display: inline-block;
  }
}
@media only screen and (max-width: 796px) {
  .middle-close .rightpanel .dashboard-col,
  .middle-close .rightpanel .rate-col,
  .middle-close .rightpanel .speaker-col,
  .middle-close .rightpanel .fullview-col {
    display: none;
  }
}

.delete-body {
  margin-left: 129px;
  margin-top: 58px;
  margin-bottom: 89px;
  font-size: 18px;
  line-height: 36px;
}
.btn {
  font-weight: 400;
  font-size: small;
}

.sharePlay .video-right-expand,
.sharePlay .video-left-expand,
.sharePlay .loading,
.sharePlay .trans-content {
  display: block;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-bottom: 2px;
}

/*left panel shrink nav*/
.left-panel-shrink-col1 select {
  font-size: 18px;
  line-height: 36px;
  padding-top: 10px;
  border: none;
  background: transparent;
}
.left-panel-shrink-col1.col img,
.left-panel-shrink-col2.col-lg-2 {
  cursor: pointer;
  padding: 6px;
}
.left-panel-full-col2 img {
  margin-top: 10px;
}
.left-panel-shrink-col2 img {
  margin-top: 1px !important;
}

.refresh {
  background-color: #a9acbd;
  background: url("../public/assets/img/refresh.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px !important;
  /*margin-left:20px;*/
}

.settings_integration {
  background-color: #a9acbd;
  background: url("../public/assets/img/setting_blue_integrations.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px !important;
  /*margin-left:20px;*/
}

.settings {
  background-color: #a9acbd;
  background: url("../public/assets/img/setting_blue.svg");
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px !important;
  /*margin-left:20px;*/
}
.toast-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999999;
}

.toast-info {
  color: white;
  font-size: 13px;
  background-color: #0052cc !important;
  border-color: #d3eef6;
}
.toast-danger {
  color: white;
  font-size: 13px;
  background-color: red !important;
  border-color: #d3eef6;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.toast {
  /* padding: 10px; */
  min-width: 400px;
  /* max-width: 600px; */
  border-width: medium;
  border-radius: 20px;
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out !important;
  animation: toast-in-right 0.7s;
  /*transform: translate3d(35vw, 26px, 0px);*/
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 10px;
  margin-bottom: 15px;
  width: 400px;
  max-height: 100px;
  border-radius: 12px 12px 12px 12px;
  box-shadow: 0 0 10px #999;
  opacity: 0.9;
  background-position: 15px;
  background-repeat: no-repeat;
}

.activeFilter {
  margin-right: 4px;
  width: 30px;
  height: 16px;
  object-fit: contain;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  border-radius: 0px;
}

.activeSeachFilter {
  border: 1px solid rgba(2, 82, 204, 0.1);
}

.activeSeachFilters {
  border: 1px solid rgba(2, 82, 204, 0.1);
}
.btn-secondary.activeFilter:focus {
  /*box-shadow: 0 0 0 0.2rem rgba(2, 82, 204, 0.5);*/
}

.transcriptsCommentBox ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.transcriptsCommentBox {
  /*width: 450px;*/
  margin: 10px 0 0;
  padding: 16px 16px 16px 12px;
  background-color: ghostwhite;
  position: relative;
  border: 1px solid rgba(0, 84, 204, 0.5);
  border-radius: 8px;
}

.transcriptsCommentBox:after,
.transcriptsCommentBox:before {
  bottom: 100%;
  left: 8%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.transcriptsCommentBox:after {
  border-color: rgba(241, 241, 241, 0);
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
}
.transcriptsCommentBox:before {
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #0052cc;
  border-width: 13px;
  margin-left: -13px;
}

.listComment li + li {
  margin-top: 15px;
}

.userData {
  display: flex;
  align-items: center;
}

.userImg {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 100%;
}

.userImg img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.userName {
  margin: 0 8px;
  font-size: 14px;
  font-weight: 600;
  color: #298ce8;
}

.userData .time {
  font-size: 12px;
  color: #70738b;
}

.commentOfList li {
  margin-top: 5px;
  font-size: 14px;
  color: #2d323c;
}

.userCommentInputArea {
  display: flex;
  align-items: flex-start;
  width: 95%;
}

.userCommentInput {
  padding: 8px 8px 90px 12px;
  border-radius: 8px;
  background-color: rgb(0, 84, 204, 0.05);
  border: none;
  font-size: 14px;
  width: 95%;
  color: #a9acbd;
  margin-left: 8px;
}

.userCommentBtns {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  padding-top: 10px;
}

.formBtn {
  height: 36px;
  margin: 0 12px 0 0;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #0052cc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #0052cc;
  transition: all ease-in-out 0.3s;
}

.formBtn:hover {
  background: #0052cc;
  color: #fff;
}

.formBtn.fillBtn {
  background: #0052cc;
  color: #fff;
  border: 1px solid #0052cc;
}

.formBtn.fillBtn:hover {
  background: #fff;
  color: #0052cc;
}

.userCommentBtns button {
  margin: 0;
}

.userCommentBtns button + button {
  margin: 0 0 0 12px;
}

.userCommentForm {
  margin-top: 16px;
}

.loginRightPane {
  padding-top: 2px;
}
.keybenefitsdiv {
  min-width: auto;
  padding: 1rem;
}

.key-features-subheading {
  font-size: 15px;
  text-align: left;
  padding-bottom: 2rem;
}

.list-2 {
  display: block;
  height: 43%;
  margin-bottom: 10px;
  padding-left: 0;
  padding-top: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  list-style-type: none;
}

.list-2.tow {
  padding-left: 0px;
}

.pricing-list-blue {
  padding: 2px 2px 2px 28px;
  background-image: url("../public/assets/img/blueCheck.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  direction: ltr;
  font-family: inherit;
  color: #202124;
  text-align: left;
  background-size: 18px 18px;
}

.pricing-list-blue.bold {
  font-family: inherit;
  font-weight: bold;
}

.pricing-description {
  padding-top: 10px;
  font-size: 13px;
  font-weight: 400;
  height: 5rem;
  font-family: inherit;
  display: block;
}

.pricing-fixed {
  font-family: inherit;
  height: 12rem;
  display: block;
  padding-bottom: 2rem;
}

.list-item-10-grey {
  padding-top: 3px;
  padding-left: 20px;
  background-image: url("../public/assets/img/grey_check.svg");
  background-position: 0px 4px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  font-family: inherit;
  color: #202124;
}
.list-item-10-grey.bold {
  font-family: inherit;
  font-weight: bold;
  color: #202124;
}

.list-item-10-salmon {
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 5px;
  padding-right: 0px;
  padding-left: 20px;
  background-image: url("../public/assets/img/salmon.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  direction: ltr;
  font-family: inherit;
  /*font-weight: 500;*/
  color: #202124;
  text-align: left;
}

.list-item-10-salmon.bold {
  font-family: inherit;
  font-weight: bold;
  color: #202124;
}

.list-item-10-gold {
  padding-top: 3px;
  padding-left: 20px;
  background-image: url("../public/assets/img/gold_check.svg");
  background-position: 0px 4px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  font-family: inherit;
  /*font-weight: 500;*/
  color: #202124;
}

.list-item-10-gold.bold {
  font-family: inherit;
  font-weight: bold;
  color: #202124;
}
.plan-paragraph {
  height: 18%;
}

.plan-subheading {
  margin-top: 20px;
  background-color: #c2bebe;
  font-size: 20px;
  line-height: 24px;
}

.popular {
  background-color: #ffd954;
  margin: 10px;
  padding: 5px 8px 5px 8px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: bold;
  color: #000;
}
.onboardingTitle {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  background-color: #ffd954;
  display: block;
  margin-bottom: 4px;
}
.check-space {
  margin-right: 15px;
}
.onboardingWarning {
  /*background: #f0483e;*/
  border-radius: 10px;
  width: 100%;
  height: auto;
  margin-top: 15px;
  padding: 10px;
  color: #2d323c;
  font-size: 14px;
}

.onboardingWarningReduced {
  /*background: #f0483e;*/
  border-radius: 10px;
  width: 30%;
  height: auto;
  margin-top: 15px;
  padding: 10px;
  color: #2d323c;
  font-size: 13px;
}
.bigLeftBanner {
  background-color: #5601f9;
  align-content: center;
  min-height: 100vh;
  white-space: pre-wrap;
}
@media only screen and (max-width: 1000px) {
  .bigLeftBanner {
    display: none;
  }
  .loginRightPane {
    padding-top: 5px;
    width: auto;
  }
  .Rectangle-140 {
    width: auto;
  }
  .col-6 {
    width: 100% !important;
  }
}
.loginParentContainer {
  max-width: 100%;
  /*overflow-x: hidden;*/
  /*overflow-y: auto;*/
}

.banner {
  /*background-color: #5859ff !important;*/
  background-color: #ffe47b !important;
  color: #2d323c;
  margin-bottom: 0px;
  padding: 10px 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.banner p {
  margin: 0;
  margin-right: 12px;
}

.banner a {
  color: #2d323c;
  text-decoration: underline;
}

.newParentContainer {
  max-width: 100%;
  /*overflow-x: hidden;*/
  /*overflow-y: auto;*/
}

.trialText {
  color: #2d323c;
  font-weight: 700;
  max-height: 25px;
  text-align: center;
  cursor: default !important;
  font-size: 12px;
  padding-top: 10px;
}

.trialDays {
  margin-left: -12rem;
}

.trialExpired {
  margin-top: 1px;
  margin-left: -10rem;
  border: 1px solid #f5c000;
  padding: 0;
  border-radius: 10px;
  width: 100px;
  font-size: 14px;
  line-height: 1rem;
}

.loginContainer {
  min-height: 100vh;
  height: 100vh;
  padding-top: 0px !important;
}

.loginMainRow {
  min-height: 100vh;
  min-width: 100vw;
  padding: 0 !important;
}

.microsoftSignUp {
  white-space: nowrap;
  padding-top: 1.5rem;
  /*max-width: 30% !important;*/
  max-width: 30%;
}

.centerContent {
  margin: auto;
  align-content: center;
  justify-content: center;
  display: flex;
}

.haveAccount {
  padding-top: 0.6rem;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #2d323c;
  white-space: nowrap;
  word-break: keep-all;
}

.googleStyle {
  display: flex;
  alignitems: center;
  justifycontent: center;
  width: 215px !important;
  height: 44px !important;
  margin-left: 10px;
}

.microsoftButton {
  margin-top: 30px;
  margin-left: 10px;
  height: 41px !important;
}

.filter-panel-dropdown {
  position: absolute;
  z-index: 1;
  width: 370px;
  padding: 10px;
  top: 5%;
  right: 1%;
  min-height: 360px;
  /* height: 300px; */
  overflow: hidden;
  background: white;
  border: 1px solid var(--mainColor);
  border-radius: 20px;
  margin-right: 2px;
}

.filter-panel-dropdown-form {
  border-radius: 20px;
  padding: 3px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 300px;
}

.home {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/home.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.video {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/video.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.grid {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background: url("../public/assets/img/grid.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

 .sidebar-icon-small {
   width: 24px !important;
   height: 24px !important;
 }


.learning-sidebar-icon,
.gengrid-sidebar-icon,
.magic-sidebar-icon,
.deals.sidebar-icon,
.accounts.sidebar-icon,
.playlists.sidebar-icon,
.analytics.sidebar-icon,
.coaching.sidebar-icon,
.home.sidebar-icon,
.video.sidebar-icon {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}

.magic {
    background-image: url('../public/assets/img/genAISearch.svg');
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.learning {
  background-image: url('../public/assets/img/learning.svg');
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gengrid {
  background-image: url('../public/assets/img/genAIGrid.svg');
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.deals {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/deals.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.accounts {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/accounts.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.playlists {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/play-list-white.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.analytics {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/analytics.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.coaching {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/coaching.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.nav-line svg {
  /*left: 10%;*/
  position: relative;
  /* align-items: center; */
  /* display: inline; */
}

.joinmeeting {
  margin: -1px 0px 0px 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  max-height: 10px;
  border: none;
  background-color: #008c32;
  color: white;
  font-size: 10px;
  word-break: keep-all;
  white-space: nowrap;
}

.joinLiveMeeting {
  float: right;
  font-size: 11px;
  margin-top: 10px;
  width: 8em;
  align-items: center;
  background-color: rgba(88, 89, 255, 1);
  border: 1px solid rgba(88, 89, 255, 1) !important;
  color: white;
}

.joinLiveMeeting:hover {
  background-color: #ffffff !important;
  color: rgba(88, 89, 255, 1) !important;
  border: 1px solid rgba(88, 89, 255, 1) !important;
  box-shadow: none !important;
}

.followupMeeting .chatboxContainer {
  position: relative;
  max-width: 100%;
  /* width: 53%; */
  padding: 5px 5px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: calc(100vh - 26rem);
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 0.5px solid rgba(74, 20, 91, 0.02);
  /*background: rgba(74, 20, 91, 0.02);*/
  background-color: white;
}
.bottomComment.hightUnset {
  height: auto !important;
}

.bottomComment {
  height: calc(100vh - 36rem) !important;
}
/* Chat Box CSS start*/
.chatboxContainer {
  display: none;
}
.middle-close .chatboxContainer,
.right-close .chatboxContainer,
.content-expaned .video-content.right-close .chatboxContainer {
  display: none;
}
.middle-close .followupMeeting.chatboxContainer,
.content-expaned .followupMeeting.chatboxContainer {
  position: relative;
  max-width: 100%;
  /* width: 53%; */
  padding: 5px 5px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: calc(100vh - 36rem);
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 0.5px solid var(--topBannerColor02);
  /*background: var(--topBannerColor02);*/
  background-color: white;
}
.video-content.middle-close .chatboxContainer .playBtn {
  top: 23px;
  right: 12px;
}
.video-content.middle-close.right-close .chatboxContainer .playBtn {
  right: 27px;
  top: 0px;
}
.content-expaned .chatboxContainer {
  padding-left: 10px;
}
.hide-content.middle-close .chatboxContainer {
  padding-left: 23px;
}
.right-close .chatboxContainer {
  width: calc(100% - 6px) !important;
}
.content-expaned .right-close .chatboxContainer {
  width: calc(100% - 15px) !important;
}
.video-content.middle-close .chatboxContainer .playBtn {
  top: 23px;
  right: 12px;
}
.video-content.middle-close.right-close .chatboxContainer .playBtn {
  right: 27px;
  top: 0px;
}
.content-expaned .chatboxContainer {
  padding-left: 10px;
}
.hide-content.middle-close .chatboxContainer {
  padding-left: 23px;
}
.right-close .chatboxContainer {
  width: calc(100% - 6px) !important;
}
.content-expaned .right-close .chatboxContainer {
  width: calc(100% - 15px) !important;
}
.chatboxContainer .commentBox,
.chatboxContainer .subCommentBox {
  width: 100%;
  height: auto;
  max-height: 100%;
  flex-grow: 0;
  padding: 15px 15px 10px;
  border-radius: 16px;
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.05);
  background-color: var(--topBannerColor02);
  margin-top: 10px;
  border: 1px solid var(--topBannerColor1);
}
.comment-column {
  display: flex;
  justify-content: space-between;
}
.commentBox .comment-reply-column {
  display: flex;
  padding-left: 15px;
}

.commentBox .comment-column .comment-column-first {
  display: flex;
  align-items: center;
}
.commentBox .profile-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commentBox .playBtn {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  background-color: #e6eefa;
  border-radius: 100%;
  cursor: pointer;
}
.commentBox .playBtn::after {
  content: "";
  display: inline-block;
  position: relative;
  top: 1px;
  left: 8px;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #0052cc;
}

.commentBox .actionBtn {
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.commentBox .actionBtn.editBtn {
  background-color: inherit;
}

.commentBox .actionBtn.deleteBtn {
  background-color: inherit;
}

.commentBox .comment-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chatUsername span {
  display: inline-block;
  margin-left: 7px;
  font-size: 14px;
  font-weight: 500;
  color: #2d323c;
}
.chatAdded span {
  display: inline-block;
  margin-left: 7px;
  font-size: 11px;
  font-weight: 400;
  color: #2d323c;
}
.chatTime {
  display: inline-block;
  margin-left: 15px;
}
.chatText p {
  margin: 0px 10px;
  font-size: 12px;
  word-wrap: break-word;
  word-break: break-word;
}
.chatboxContainer .subCommentBox {
  width: 94%;
  padding: 15px 15px 10px 20px !important;
}
.replyContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.replyContainer span {
  display: inline-block;
  color: #0052cc;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 25px;
}
.enterComment .textArea {
  width: 100%;
  height: 133px;
  flex-grow: 0;
  border-radius: 16px;
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.05);
  background-color: #fff;
  margin-top: 10px;
  resize: none;
}
.progress {
  height: 2rem;
  overflow: hidden;
  font-size: 1.2rem;
  border-radius: 0.9rem;
  font-weight: 600;
  color: white;
}

.sharePlay .trans-content {
  display: block;
}
.commentBox .subCommentBox {
  position: absolute;
  left: 40px;
  margin-top: 10px;
}

.comments-wrapper {
  /*padding: 10px 10px;*/
  height: calc(100vh - 25rem);
  overflow-y: auto;
}

.shared {
  position: relative;
  display: flex;
  margin-right: 5px;
  font-size: 12px;
}
.shared-icon {
  margin: 0px 5px;
}
.shared-icon .shareOnHover {
  display: none;
}
.shared-icon:hover .shareOnHover {
  background: #2d323c;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  /* bottom: 0; */
  z-index: 99;
  width: 172px;
}

.shared1 {
  position: relative;
  display: flex;
  margin-right: 5px;
  font-size: 12px;
}
.shared-icon1 {
  margin: -5px;
}
.shared-icon1 .shareOnHover {
  display: none;
}
.shared-icon1:hover .shareOnHover {
  background: #2d323c;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  /* bottom: 0; */
  z-index: 99;
  width: 172px;
}

.attendeeOnHover span {
  padding: 5px 5px 5px;
}

.groups {
  margin: 0px 5px;
}

.groups .attendeeOnHover {
  display: none;
}

.groups:hover .attendeeOnHover {
  background: #2d323c;
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  /*left: 60%;*/
  z-index: 99;
  width: 20rem;
}

.shareOnHover span {
  padding: 13px 15px 9px;
  font-size: 12px;
  font-weight: 400;
  color: white;
}

.commentBox.subCommentBox.bottomBox {
  position: absolute;
  margin-top: 10px;
}
.chatboxContainer .commentBox.subCommentBox.bottomBox {
  position: inherit;
}

.followupContainer .bottomBox {
  left: 0px;
  width: 90%;
}

.newBox {
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.05);
  background-color: #f9f9fb;
  margin: 2px 5px 2px 5px;
  border: 1px solid var(--topBannerColor1);
  border-radius: 16px;
  padding: 10px !important;
}

.newChat {
  margin-top: -8px !important;
}

.bottomBox {
  height: auto;
  max-height: 100%;
  flex-grow: 0;
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0 0 14px 0 rgba(45, 50, 60, 0.05);
  background-color: #f9f9fb;
  margin-top: 10px;
  margin-left: 10px;
  border: 0.5px solid var(--topBannerColor1);
  width: 26%;
}

.richTextEditor {
  width: 100%;
  min-height: 30rem;
  border: 0.5px solid #e4e5ee;
  padding: 10px 5px;
  border-radius: 10px;
  font-size: 14px;
}

.followupTranscript {
  width: 100% !important;
  min-height: calc(100vh + 10rem) !important;
  max-height: 90%;
  overflow: hidden;
  padding: 0px;
  margin-top: 10px;
}

.scorecardTranscript {
  width: 100% !important;
  min-height: calc(100vh - 40rem) !important;
  max-height: 80%;
  overflow: hidden;
  padding: 0px;
  margin-top: 10px;
}

.keyMomentsInput {
  margin: 0px;
  font-size: 12px;
  height: 3rem;
  width: 250px;
  display: flex;
  justify-content: flex-start;
  padding: 0px 10px 0px 10px;
}

.react-multi-email {
  margin: 0;
  max-width: 92%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.1em 0.5em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.4rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.1em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  border-color: #85b7d9;
  background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #cccccc;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: #2d323c;
  text-transform: none;
  border: 0 solid transparent;
  border-radius: 0.3rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.react-multi-email [data-tag] [data-tag-item] {
  max-width: 90%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

label.label1 input[type="file"] {
  position: absolute;
  top: -1000px;
}
.label1 {
  cursor: pointer;
  border: 1px solid #0052cc;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 5px;
  background: #0052cc;
  display: block;
  color: #ffffff;
  max-height: 3rem;
  height: 36px;
  width: 120px;
  max-width: 120px;
  resize: none;
  text-align: center;
}
.label1:hover {
  background: #fff;
  color: #0552cc;
}
/*.label1:active {*/
/*  background: #0052CC;*/
/*  color: #FFFFFF;*/
/*}*/
.label1:invalid + span {
  color: #000000;
}
.label1:valid + span {
  color: #ffffff;
}

label.label2 input[type="file"] {
  position: absolute;
  top: -1000px;
}
.label2 {
  cursor: pointer;
  border: 1px solid #0052cc;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 5px;
  background: #0052cc;
  display: block;
  color: #ffffff;
  max-height: 3rem;
  height: 36px;
  width: 200px;
  max-width: 200px;
  resize: none;
  text-align: center;
}
.label2:hover {
  background: #fff;
  color: #0552cc;
}
/*.label1:active {*/
/*  background: #0052CC;*/
/*  color: #FFFFFF;*/
/*}*/
.label2:invalid + span {
  color: #000000;
}
.label2:valid + span {
  color: #ffffff;
}

.mymodal {
  max-width: 100% !important;
  max-height: inherit !important;
  margin: -1px !important;
}

.form-control.borderless:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.home-left-container {
  height: calc(100vh - 50px);
  background-color: #ffffff;
  /*width: calc(100vw - 70px);*/
  border-right: 1px solid var(--topBannerColor1);
  padding: 2rem 1rem 1rem 2.5rem;
  margin: 0 auto;
  flex: 0 0 94%;
  width: 80%;
}

.home-left-container .title {
  font-size: 1.5em;
  font-width: 600;
  text-align: left;
  top: 50%;
  left: 50%;
  margin: auto;
  /*padding: 2em 1em 2em 4em;*/
}

.home-right-container {
  height: calc(100vh - 50px);
  width: 100%;
  margin-left: 5px;
  background-color: #ffffff;
}

.blockTitle {
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  margin: auto;
  padding: 0.5em;
}

.blockTitleLeft {
  text-align: left;
  margin: auto;
  font-size: 16px;
}

.right-block {
  padding: 10px;
  margin: 1px;
  border-bottom: 1px solid var(--topBannerColor1);
}
.rightTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding: 10px 0px 0px 0px;
}

.extnButton {
  background: white;
  border: none;
  color: #0052cc;
  align-items: center;
  margin: auto;
}

.extension {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background: url("../public/assets/img/extension.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  margin-right: 10px;
}

.warning {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: url("../public/assets/img/warning.svg");
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  cursor: pointer;
  overflow-wrap: break-word;
}

.integrations {
  color: white;
  background-color: var(--mainColor);
  border: none;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;
  align-items: center;
  white-space: nowrap;
}

.integrations:hover {
  background-color: #ffffff !important;
  color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
  box-shadow: none !important;
}
.integrations:active {
  background-color: #ffffff !important;
  color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
  box-shadow: none !important;
}

.best_practices {
  background-color: white;
  color: #0052cc;
  border: 1px solid #0052cc;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;
  padding-top: 8px;
  height: 100% !important;
  font-weight: normal;
}

/* Tooltip container */
.tooltip1 {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 9.5rem;
  height: 6rem;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  margin-left: 4px;
  /* Position the tooltip
  overflow-wrap: break-word;text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip1:hover .tooltiptext {
  visibility: visible;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 9.5rem;
  height: 4rem;
  background-color: #2d323c;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 8px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiparrow {
  visibility: visible;
}

.scorecard-tile {
  border: 1px solid rgba(88, 89, 255, 1);
  background-color: rgba(88, 89, 255, 0.1);
  margin: 10px 0px;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
}

.scorecard-tile:hover {
  /* a shade of mainColor*/
  background-color: rgba(88, 89, 255, 0.2);
}

.scorecard-note {
  font-size: 0.85rem;
  color: #202124;
  height: 5rem;
  min-height: 5rem;
  width: 90%;
  margin-left: 15px;
}

.scorecard-ul {
  list-style: none;
  padding: 0px;
}

.scorecard-ul li {
  cursor: pointer;
  margin: 10px 1%;
  text-align: left;
  padding: 16px 5%;
  border: 1px solid #e4e5ee;
  border-radius: 8px;
}
.scorecard-ul li.active {
  /* a shade of mainColor*/
  background-color: rgba(88, 89, 255, 0.05);
  border-color: rgba(88, 89, 255, 1);
}

.date-range-picker {
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 16px;
  outline: 0;
  min-height: 38px;
  padding: 2px 8px;
  width: 100%;
  cursor: pointer;
}

.date-range-picker::placeholder {
  color: hsl(0, 0%, 50%);
}

.collaboration-tab-content {
  height: 20rem;
  overflow-y: auto;
  border: none;
}

.comment-card {
  cursor: pointer;
  padding: 0.75rem;
  border: none;
  border-bottom: 1px solid var(--topBannerColor1);
}

.comment-card:hover {
  box-shadow: 0 0 5px rgba(88, 89, 255, 0.9);
  border: 1px solid rgba(88, 89, 255, 1);
}

.comment-text {
  background-color: #F5F6FA;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 10px 40px 10px 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.delete-comment-modal {
  max-width: 700px;
}

.delete-comment-modal .modal-body {
  text-align: center;
}

.delete-comment-modal .modal-body p {
  font-size: 18px;
}

.ask-search {
  border: 1px solid rgba(2, 82, 204, 0.1);
  overflow: hidden;
  height: 100%;
  padding: 0 0px 0 0px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
}

.card.exclude-style {
  position: initial;
  display: initial;
  flex-direction: initial;
  min-width: initial;
  word-wrap: initial;
  background-color: initial;
  background-clip: initial;
  border: initial;
  border-radius: initial;
  margin-bottom: initial;
}

.ask-search.hover-suggested {
  border: 1px solid var(--mainColor);
}

.ask-search-input {
  padding-left: 12px;
  padding-right: 12px;
  max-width: calc(100% - 1px);
  font-size: 12px;
  overflow: hidden;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  border: 0.3px rgb(0, 82, 204);
  border-radius: 50px;
  height: 36px;
}

.ask-search-button {
  height: 100% !important;
  border: none;
  border-radius: 22px;
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--mainColor);
  color: white;
}
.ask-search-button:hover {
  background-color: rgba(68, 68, 251, 0.329) !important;
  border: 1px solid rgba(68, 68, 251, 0.329) !important;
}
.ask-search-button:focus {
  background-color: rgba(68, 68, 251, 0.5) !important;
  border: 1px solid rgba(68, 68, 251, 0.5) !important;
  outline: none !important;
  box-shadow: none !important;
}

.ask-clear-icon {
  background: url('../public/assets/img/clear.svg');
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  min-width: 8px;
  cursor: pointer;
  background-Repeat: no-repeat;
}

.suggested-question-container {
  /*background-color: rgb(242 186 242 / 50%);*/
  background-color: var(--topBannerColor1);
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ask-copyButton {
  gap: 4px;
  height: 24px !important;
  padding: 2px 8px;
  align-items: center;
  font-size: 10px;
  border-radius: 16px;
  color: white !important;
  background-color: rgb(152 152 152 / 75%) !important;
  border: 1px solid lightgrey !important;
  display: flex;
}
.ask-copyButton:hover {
  background-color: rgba(68, 68, 251, 0.329) !important;
  border: 1px solid rgba(68, 68, 251, 0.329) !important;
}
.ask-copyButton:focus {
  background-color: rgba(68, 68, 251, 0.5) !important;
  border: 1px solid rgba(68, 68, 251, 0.5) !important;
  outline: none !important;
  box-shadow: none !important;
}

.ask-priorToggle {
  gap: 4px;
  height: 24px !important;
  padding: 2px 8px;
  align-items: center;
  font-size: 10px;
  border-radius: 16px;
  border: 1px solid lightgrey !important;
  display: flex;
  background-color: white !important;
  color: #2D323C !important;
  box-shadow: none;
  outline: none;
}
.ask-priorToggle:hover {
  background-color: rgba(88, 89, 255, 0.2) !important;
  border: 1px solid rgba(88, 89, 255, 0.2) !important;
}
.ask-priorToggle:focus {
  outline: none !important;
  box-shadow: none !important;
}

.ask-priorToggle.active {
  background-color: rgba(88, 89, 255, .1) !important;
  border: 1px solid rgba(88, 89, 255, .1)  !important;
  color: #2D323C !important;
  font-weight: bold;
}

/* Adjust the slider bar to align with the handle */
.noUi-target {
  height: 8px;
  background-color: #d3d3d3 !important;
  padding: 0 5px; /* Adds padding to the slider bar equal to half the handle width */
}
.noUi-connect {
  background: #f4f4f4 !important;
}

/* Style for the handles */
.noUi-handle {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  background-color: #0052cc;
  border: 1px solid #0052cc;
  top: -8px;
  cursor: pointer;
  background-image: none !important;
  box-shadow: none !important;
  margin-left: 2px !important;
  padding: 0 10px;
}

.noUi-handle::before,
.noUi-handle::after {
  content: none !important;
}

.noUi-connects {
  left: 10px;
  right: 10px;
}

.noUi-tooltip {
  bottom: -25px !important;
  top: auto !important;
  border: none !important;
  font-size: 10px !important;
  background-color: white !important;
  color: #2d323c !important;
  padding: 5px 5px !important;
  border-radius: 4px !important;
  z-index: 1000 !important;
}

